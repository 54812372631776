import React from 'react';
import { styled, StyledProps } from '@glitz/react';

const RotateCircle = ({ compose }: StyledProps) => {
  return (
    <styled.Svg
      css={compose()}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17 17"
      fill="#004C97"
    >
      <g clipPath="url(#clip0_2622_11864)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.57024 1.90463C9.00152 1.70325 10.4595 1.97249 11.7244 2.67177C12.9894 3.37106 13.9928 4.4625 14.5835 5.78165C15.1742 7.10081 15.3202 8.57621 14.9995 9.98555C14.6788 11.3949 13.9088 12.6618 12.8054 13.5955C11.702 14.5291 10.3251 15.0789 8.88212 15.1619C7.43913 15.245 6.00824 14.8568 4.80504 14.0559C3.60184 13.255 2.69151 12.0848 2.21122 10.7216C2.08887 10.3743 2.2712 9.99361 2.61847 9.87127C2.96574 9.74892 3.34644 9.93125 3.46878 10.2785C3.85302 11.3691 4.58128 12.3053 5.54384 12.946C6.5064 13.5867 7.65111 13.8972 8.8055 13.8308C9.95989 13.7644 11.0614 13.3245 11.9441 12.5776C12.8268 11.8307 13.4429 10.8172 13.6994 9.68969C13.956 8.56222 13.8392 7.3819 13.3666 6.32658C12.8941 5.27125 12.0913 4.3981 11.0794 3.83867C10.0674 3.27925 8.90102 3.06386 7.756 3.22496C6.61098 3.38606 5.54932 3.91492 4.73101 4.73185C4.72625 4.7366 4.72142 4.74128 4.71652 4.74588L2.8497 6.50005H5.16667C5.53486 6.50005 5.83333 6.79853 5.83333 7.16672C5.83333 7.5349 5.53486 7.83338 5.16667 7.83338H1.16667C0.798477 7.83338 0.5 7.5349 0.5 7.16672V3.16672C0.5 2.79853 0.798477 2.50005 1.16667 2.50005C1.53486 2.50005 1.83333 2.79853 1.83333 3.16672V5.62547L3.79632 3.78094C4.81823 2.76392 6.14237 2.10552 7.57024 1.90463Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_2622_11864">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.5 0.5)"
          />
        </clipPath>
      </defs>
    </styled.Svg>
  );
};

export default styled(RotateCircle);
