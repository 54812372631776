import KexLink from '../Shared/KexLink/KexLink';
import React, { FC } from 'react';
import { styled } from '@glitz/react';
import { Logo, SmallArrowRightIcon } from '../Shared/Icons';
import { theme } from '../Theme';
import AlertCircle from '../Shared/Icons/AlertCircle';
import RotateCircle from '../Shared/Icons/RotateCircle';
import { Style, StyleOrStyleArray } from '@glitz/type';
import { ITopFormPanel } from './Models/TopFormPanel';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import { media } from '@glitz/core';

const lastStepAction = 'summaryPage';

export const TopFormPanel: FC<ITopFormPanel> = ({
  helpLabel,
  startOverLabel,
  steps,
  setCurrentPage,
  currentPage,
  setIsHelpModalShowed,
  setIsStartOverModalShowed,
  totalSelectedProducts,
}) => {
  const isZeroProductsSelected = totalSelectedProducts === 0;
  const { siteRoute } = useAppSettingsData();

  const isClickAvailable = (index: number) =>
    !isZeroProductsSelected || index <= 1;
  const handleStepClick = (index: number, stepAction: string) => {
    if (isClickAvailable(index)) {
      setCurrentPage({ action: stepAction, index });
    }
  };

  return (
    <TopPanel>
      <KexLink href={`/${siteRoute}`}>
        <TepeLogo />
      </KexLink>
      <Steps>
        {steps.map(
          (s, i) =>
            s.title && (
              <Step onClick={() => handleStepClick(i, s.action)}>
                <StepText
                  css={
                    isClickAvailable(i)
                      ? currentPage.action === s.action
                        ? StyledActiveStep
                        : {}
                      : StyledDisabledStep
                  }
                >
                  {s.title}
                </StepText>
                {s.action !== lastStepAction && (
                  <ChevronIcon
                    fill={
                      isClickAvailable(i + 1)
                        ? theme.blueDark
                        : theme.loadMoreBar
                    }
                  />
                )}
              </Step>
            )
        )}
      </Steps>
      <ButtonsSection>
        <Step onClick={() => setIsHelpModalShowed(true)}>
          <AlertCircleIcon />
          <StepText>{helpLabel}</StepText>
        </Step>
        <Step
          onClick={() => setIsStartOverModalShowed(true)}
          css={startOverStyle}
        >
          <RotateCircleIcon />
          <StepText>{startOverLabel}</StepText>
        </Step>
      </ButtonsSection>
    </TopPanel>
  );
};

const TopPanel = styled.div({
  width: '80%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  ...media(theme.mediaQuery.mediaMaxLarge, {
    width: '95%',
  }),
});

const startOverStyle: Style = {
  ...media(theme.mediaQuery.mediaMaxHuge, {
    display: 'none',
  }),
};

const TepeLogo = styled(Logo, {
  width: '81px',
  height: '37px',
});

const Steps = styled.div({
  display: 'flex',
  gap: '22.5px',
});

const Step = styled.div({
  fontWeight: theme.fontWeight.bold,
  fontSize: theme.beta,
  lineHeight: theme.lineHeight.normal,
  color: theme.blueDark,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
});

const ChevronIcon = styled(SmallArrowRightIcon, {
  width: theme.spacing(4),
  height: theme.spacing(3.25),
  marginLeft: '22.5px',
  ...media(theme.mediaQuery.mediaMaxHuge, {
    display: 'none',
  }),
});

const StepText = styled.span({
  padding: {
    y: theme.spacing(4),
  },
});

const AlertCircleIcon = styled(AlertCircle, {
  width: theme.spacing(4.25),
  height: theme.spacing(4.25),
  marginRight: theme.spacing(1.5),
});

const RotateCircleIcon = styled(RotateCircle, {
  width: theme.spacing(4.25),
  height: theme.spacing(4.25),
  marginRight: theme.spacing(1.5),
});

const StyledActiveStep: StyleOrStyleArray = {
  border: {
    bottom: {
      style: 'solid',
      width: theme.spacing(0.5),
      color: theme.blueDark,
    },
  },
};

const StyledDisabledStep: StyleOrStyleArray = {
  color: theme.loadMoreBar,
  cursor: 'not-allowed',
};

const ButtonsSection = styled.div({
  display: 'flex',
  gap: `${theme.spacing(4)}px`,
});
