import React from 'react';
import { styled, StyledProps } from '@glitz/react';

const EmailIcon = ({ compose }: StyledProps) => {
  return (
    <styled.Svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={compose()}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.07089 3.70263L7.99984 7.8529L13.9288 3.70263C13.8185 3.48462 13.5918 3.33333 13.3332 3.33333H2.6665C2.40786 3.33333 2.18114 3.48462 2.07089 3.70263ZM13.9998 5.28044L8.38215 9.21282C8.1526 9.3735 7.84708 9.3735 7.61753 9.21282L1.99984 5.28044V12C1.99984 12.3651 2.30136 12.6667 2.6665 12.6667H13.3332C13.6983 12.6667 13.9998 12.3651 13.9998 12V5.28044ZM0.666504 4C0.666504 2.89848 1.56498 2 2.6665 2H13.3332C14.4347 2 15.3332 2.89848 15.3332 4V12C15.3332 13.1015 14.4347 14 13.3332 14H2.6665C1.56498 14 0.666504 13.1015 0.666504 12V4Z"
        fill="white"
      />
    </styled.Svg>
  );
};

export default styled(EmailIcon);
