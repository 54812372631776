import React, { FC, useState } from 'react';
import { styled } from '@glitz/react';
import { Style } from '@glitz/type';

import { theme } from '../Theme';
import ArrowSmall from '../Shared/Icons/ArrowSmall';
import { IFormFooter } from './Models/IFormFooter';
import EmailIcon from '../Shared/Icons/EmailIcon';
import RotateCircle from '../Shared/Icons/RotateCircle';
import { media } from '@glitz/core';
import { purchaseDataLayer } from '../utils/drtDataLayer.lib';

export const FormFooter: FC<IFormFooter> = ({
  backLabel,
  nextLabel,
  handleNextStepClick,
  handleBackStepClick,
  totalSelectedProducts,
  productsSelectedLabel,
  sendRecommendationLabel,
  isSummaryPage,
  handleSendPartlyRecommendation,
  isSendRecommendationDisabled = false,
  handleSendRecommendation,
  setIsStartOverModalShowed,
  startOverLabel,
  isEmailValid,
  setIsEmailModalFailureShown,
  productsCategories,
  selectedProductsAmount,
}) => {
  const [busy, setBusy] = useState(false);
  const isZeroProductsSelected = totalSelectedProducts === 0;
  const isTotalSelectedProductsNull = totalSelectedProducts === null;
  const nextButtonStyleArray = [
    isZeroProductsSelected ? nextButtonDisabledStyle : nextButtonStyle,
  ];
  const productsSelectedText =
    productsSelectedLabel && totalSelectedProducts !== null
      ? productsSelectedLabel.replace('{0}', totalSelectedProducts!.toString())
      : '';

  const handleNextClick = () => {
    if (isZeroProductsSelected) return;
    if (isSummaryPage) {
      sendEmail();
    } else {
      handleNextStepClick();
    }
  };

  const sendEmail = async () => {
    if (!isEmailValid) {
      return;
    }
    setBusy(true);
    try {
      const res = await handleSendRecommendation?.();
      const data = await res.json();
      if (data.Success) {
        purchaseDataLayer(selectedProductsAmount, productsCategories, data.Id);
        handleNextStepClick();
      } else {
        setIsEmailModalFailureShown(true);
      }
    } catch (e) {
      setIsEmailModalFailureShown(true);
      console.error("Couldn't send email", e);
    } finally {
      setBusy(false);
    }
  };
  const NextButton = () => (
    <>
      {nextLabel && (
        <Button css={nextButtonStyleArray} onClick={handleNextClick}>
          <span>{nextLabel}</span>
          <NextArrow fill={theme.white} />
        </Button>
      )}
    </>
  );

  const StartOverButton = () => (
    <>
      {startOverLabel && (
        <Button
          onClick={() => setIsStartOverModalShowed(true)}
          css={startOverStyle}
        >
          <RotateCircleIcon />
          <span>{startOverLabel}</span>
        </Button>
      )}
    </>
  );

  return (
    <>
      <Footer>
        {isTotalSelectedProductsNull ? (
          <ButtonsWrapper>
            {backLabel && (
              <Button onClick={handleBackStepClick}>
                <Arrow fill={theme.blueDark} />
                <span>{backLabel}</span>
              </Button>
            )}
            <StartOverButton />
          </ButtonsWrapper>
        ) : (
          <ButtonsWrapper>
            <ProductsSelectedLabel>
              {productsSelectedText}
            </ProductsSelectedLabel>
            <StartOverButton />
          </ButtonsWrapper>
        )}
        {isSummaryPage ? (
          <ButtonsWrapper>
            {/*ToDo: Add print button when needed*/}
            {/*<SummaryButton>*/}
            {/*  <Print />*/}
            {/*  <span>Print</span>*/}
            {/*</SummaryButton>*/}
            <SummaryButton
              onClick={sendEmail}
              css={
                isSendRecommendationDisabled || !isEmailValid
                  ? {
                      ...summaryRecommendationButtonStyle,
                      ...disabledSendRecommendationStyle,
                    }
                  : summaryRecommendationButtonStyle
              }
            >
              {busy ? <div className="recommendation-spinner" /> : <Email />}
              <span>{sendRecommendationLabel}</span>
            </SummaryButton>
          </ButtonsWrapper>
        ) : (
          <>
            {!isZeroProductsSelected && !isTotalSelectedProductsNull ? (
              <ButtonsWrapper>
                <Button
                  css={sendRecommendationButtonStyle}
                  onClick={handleSendPartlyRecommendation}
                >
                  <span>{sendRecommendationLabel}</span>
                </Button>
                <NextButton />
              </ButtonsWrapper>
            ) : (
              <NextButton />
            )}
          </>
        )}
      </Footer>
    </>
  );
};

const Footer = styled.div({
  width: '100%',
  position: 'absolute',
  height: '92px',
  bottom: '0',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: theme.white,
  border: {
    top: {
      style: 'solid',
      width: theme.spacing(0.25),
      color: theme.blueGray,
    },
  },
  padding: {
    x: theme.spacing(10),
    y: theme.spacing(7.5),
  },
});

const Button = styled.button({
  padding: {
    y: theme.spacing(2),
    x: theme.spacing(4),
  },
  fontSize: theme.beta,
  fontWeight: theme.fontWeight.bold,
  lineHeight: theme.lineHeight.mediumTight,
  color: theme.blueDark,
  display: 'flex',
  gap: `${theme.spacing(2)}px`,
  border: {
    xy: {
      style: 'solid',
      width: theme.spacing(0.25),
      color: theme.blueDark,
    },
    radius: theme.spacing(8),
  },
});

const ProductsSelectedLabel = styled.span({
  color: theme.blackAndWhiteDarkGrey,
  fontSize: theme.gamma,
  fontWeight: theme.fontWeight.bolder,
  lineHeight: theme.lineHeight.normal,
});

const ButtonsWrapper = styled.div({
  display: 'flex',
  gap: `${theme.spacing(4)}px`,
  alignItems: 'center',
});

const nextButtonStyle: Style = {
  color: theme.white,
  backgroundColor: theme.blueDark,
};

const sendRecommendationButtonStyle: Style = {
  backgroundColor: theme.fairOrange,
  color: theme.white,
  border: {
    xy: {
      style: 'solid',
      width: theme.spacing(0.25),
      color: theme.fairOrange,
    },
  },
};

const nextButtonDisabledStyle: Style = {
  backgroundColor: theme.deactivatedButtonGrey,
  color: theme.white,
  cursor: 'not-allowed',
  border: {
    xy: {
      style: 'solid',
      width: theme.spacing(0.25),
      color: theme.deactivatedButtonGrey,
    },
  },
};

const Arrow = styled(ArrowSmall, {
  width: theme.spacing(4),
  height: theme.spacing(4),
});

const NextArrow = styled(ArrowSmall, {
  width: theme.spacing(4),
  height: theme.spacing(4),
  transform: 'rotate(180deg)',
});

const SummaryButton = styled.button({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: `${theme.spacing(2)}px`,
  color: theme.blueDark,
  fontSize: theme.beta,
  fontWeight: theme.fontWeight.bold,
  lineHeight: theme.lineHeight.mediumTight,
  borderRadius: theme.spacing(8),
  height: theme.spacing(12),
  padding: {
    x: theme.spacing(6),
    y: theme.spacing(4),
  },
  border: {
    xy: {
      style: 'solid',
      width: theme.spacing(0.25),
      color: theme.blueDark,
    },
  },
});

const Email = styled(EmailIcon, {
  width: theme.spacing(4),
  height: theme.spacing(4),
});

const disabledSendRecommendationStyle: Style = {
  backgroundColor: theme.fairGray,
  cursor: 'not-allowed',
  border: {
    xy: {
      style: 'solid',
      width: theme.spacing(0.25),
      color: theme.fairGray,
    },
  },
};

const summaryRecommendationButtonStyle: Style = {
  backgroundColor: theme.fairOrange,
  border: {
    xy: {
      style: 'solid',
      width: theme.spacing(0.25),
      color: theme.fairOrange,
    },
  },

  color: theme.white,
};

const RotateCircleIcon = styled(RotateCircle, {
  width: theme.spacing(4.25),
  height: theme.spacing(4.25),
});

const startOverStyle: Style = {
  border: {
    xy: {
      style: 'none',
    },
  },
  ...media(theme.mediaQuery.mediaMinHuge, {
    display: 'none',
  }),
};
