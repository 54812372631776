import React from 'react';
import { styled, StyledProps } from '@glitz/react';

interface IDotCheckMark {
  fill?: string;
}

const DotCheckMark = ({
  compose,
  fill = '#60B0BD',
}: IDotCheckMark & StyledProps) => {
  return (
    <styled.Svg
      css={compose()}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19 18"
      fill="none"
    >
      <rect x="0.5" width="18" height="18" rx="9" fill={fill} />
      <path
        d="M6.0153 8.28809C5.62073 7.87065 4.9564 7.87065 4.56183 8.28809L4.14923 8.7246C3.7849 9.11006 3.78488 9.7129 4.14918 10.0984L7.10973 13.231C7.50429 13.6485 8.16866 13.6485 8.56325 13.2311L14.8507 6.57916C15.2151 6.19368 15.2151 5.59079 14.8507 5.20532L14.4382 4.76886C14.0436 4.35142 13.3793 4.35142 12.9847 4.76886L8.56353 9.44631C8.16896 9.86375 7.50463 9.86375 7.11006 9.44631L6.0153 8.28809Z"
        fill="white"
      />
    </styled.Svg>
  );
};

export default styled(DotCheckMark);
