import React, { MouseEvent, FC } from 'react';

import { styled, theme } from '../../Theme';

import {
  missingTeethClickType,
  mouthImageLowerDotsArray,
  mouthImageTeethArray,
  mouthImageUpperDotsArray,
  pathIdSeparator,
  specialAttentionTeethClickType,
} from './mouthImageData';
import { handleDot, handleTooth, isDot, isTooth } from './mouthImageHelper';

import IMouthImage from '../Models/MouthImage.interface';
import MouthImageButtons from './MouthImageButtons';
import { Style } from '@glitz/type';

const MouthImage: FC<IMouthImage> = ({
  teethClickType,
  productColorSelection,
  mouthDotsChecked,
  setMouthDotsChecked,
  isAssignProductsPage,
  setMouthTeeth,
  mouthTeeth,
}) => {
  const isButtonAreaShown =
    !!productColorSelection?.color ||
    !!mouthDotsChecked?.entireMouth.length ||
    !!mouthDotsChecked?.specificDots.some(item => item.color);

  const defineDotStyle = (index: number) => [
    mouthDotsChecked?.specificDots?.[index].hexColor
      ? {
          fill:
            mouthDotsChecked?.specificDots?.[index].hexColor ??
            theme.loadMoreBar,
          ...mouthDotActiveStyle,
        }
      : {},
  ];

  const onMouthClick = (e: MouseEvent<SVGGElement>) => {
    const target = e.target as SVGGElement;

    if (isTooth(target) && !isAssignProductsPage && teethClickType) {
      handleTooth(target, teethClickType, setMouthTeeth);
    }

    if (isDot(target) && productColorSelection?.color && isAssignProductsPage) {
      if (
        mouthDotsChecked?.entireMouth.some(
          item => item.code === productColorSelection.code
        )
      ) {
        return;
      }
      handleDot(productColorSelection, setMouthDotsChecked!, target);
    }
  };

  return (
    <svg width="449" height="85%" viewBox="0 0 449 600" fill="none">
      <g id="Mouth" onClick={onMouthClick}>
        {isButtonAreaShown && (
          <MouthImageButtons
            setMouthDotsChecked={setMouthDotsChecked}
            productColorSelection={productColorSelection}
            entireMouthSelection={mouthDotsChecked?.entireMouth!}
            mouthTeethLength={mouthTeeth?.length}
          />
        )}
        <g id="Teeth">
          {mouthImageTeethArray.map((path, index) => (
            <g id={index.toString()}>
              <TeethPath
                id={`Body${pathIdSeparator}${index}`}
                d={path.d}
                css={
                  isAssignProductsPage && !teethClickType
                    ? { ...noPointer }
                    : mouthTeeth?.[index] === specialAttentionTeethClickType
                    ? { ...teethToSelectStyle, ...specialAttentionTeethStyle }
                    : teethToSelectStyle
                }
              />
              <OutlinePath
                id={`Outline${pathIdSeparator}${index}`}
                d={path.outlineD}
                css={
                  !isAssignProductsPage && mouthTeeth?.[index]
                    ? mouthTeeth[index] === missingTeethClickType
                      ? { ...missingTeethOutlineStyle }
                      : mouthTeeth[index] === specialAttentionTeethClickType
                      ? { ...specialAttentionTeethOutlineStyle }
                      : {}
                    : { ...noPointer }
                }
              />
            </g>
          ))}
        </g>
        {mouthTeeth?.length ? (
          <g id="Upper dots">
            {mouthImageUpperDotsArray.map(
              (path, index) =>
                mouthDotsChecked?.specificDots[index].hexColor && (
                  <g id={`Dot${pathIdSeparator}${index}`}>
                    <DotPath
                      id={`Vector${pathIdSeparator}${index}`}
                      d={path}
                      css={defineDotStyle(index)}
                    >
                      <title>
                        {mouthDotsChecked?.specificDots[index]?.name}
                      </title>
                    </DotPath>
                  </g>
                )
            )}
          </g>
        ) : (
          isButtonAreaShown && (
            <g id="Upper dots">
              {mouthImageUpperDotsArray.map((path, index) => (
                <g id={`Dot${pathIdSeparator}${index}`}>
                  <DotPath
                    id={`Vector${pathIdSeparator}${index}`}
                    d={path}
                    css={defineDotStyle(index)}
                  >
                    <title>{mouthDotsChecked?.specificDots[index]?.name}</title>
                  </DotPath>
                </g>
              ))}
            </g>
          )
        )}
        {mouthTeeth?.length ? (
          <g id="Lower dots">
            {mouthImageLowerDotsArray.map((path, index) => {
              const itemIndex = index + mouthImageUpperDotsArray.length;
              return (
                mouthDotsChecked?.specificDots[itemIndex].color && (
                  <g id={`Dot${pathIdSeparator}${itemIndex}`}>
                    <DotPath
                      id={`Vector${pathIdSeparator}${itemIndex}`}
                      d={path}
                      css={defineDotStyle(itemIndex)}
                    >
                      <title>
                        {mouthDotsChecked?.specificDots[index]?.name}
                      </title>
                    </DotPath>
                  </g>
                )
              );
            })}
          </g>
        ) : (
          isButtonAreaShown && (
            <g id="Lower dots">
              {mouthImageLowerDotsArray.map((path, index) => {
                const itemIndex = index + mouthImageUpperDotsArray.length;
                return (
                  <g id={`Dot${pathIdSeparator}${itemIndex}`}>
                    <DotPath
                      id={`Vector${pathIdSeparator}${itemIndex}`}
                      d={path}
                      css={defineDotStyle(itemIndex)}
                    >
                      <title>
                        {mouthDotsChecked?.specificDots[index]?.name}
                      </title>
                    </DotPath>
                  </g>
                );
              })}
            </g>
          )
        )}
      </g>
    </svg>
  );
};

const DotPath = styled.path({
  fill: theme.loadMoreBar,
  cursor: 'pointer',
  fillOpacity: 0.2,
  filter: 'drop-shadow(0px 3px 12px 0px rgba(0, 0, 0, 0.16))',
  ':hover': {
    fillOpacity: 0.5,
  },
});

const TeethPath = styled.path({
  fill: 'white',
  cursor: 'pointer',
});

const teethToSelectStyle: Style = {
  ':hover': {
    fill: '#F4FBFA',
  },
};

const OutlinePath = styled.path({
  cursor: 'pointer',
  fill: 'black',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
});

const mouthDotActiveStyle: Style = {
  cursor: 'normal',
  fillOpacity: 1,
  stroke: theme.white,
  strokeWidth: `${theme.spacing(1.5)}px`,
  strokeLinejoin: 'round',
  paintOrder: 'stroke',
  ':hover': {
    fillOpacity: 1,
  },
};

const missingTeethOutlineStyle: Style = {
  fill: '#EEEEEE',
};

const noPointer: Style = {
  cursor: 'normal',
};

const specialAttentionTeethOutlineStyle = {
  fill: '#005F9E',
};

const specialAttentionTeethStyle = {
  fill: '#CCDBEA',
};

export default MouthImage;
