import React from 'react';
import { styled, StyledProps } from '@glitz/react';

interface IArrowSmall {
  fill: string;
}

const ArrowSmall = ({ compose, fill }: IArrowSmall & StyledProps) => {
  return (
    <styled.Svg
      css={compose()}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.47124 2.86189C8.73159 3.12224 8.73159 3.54435 8.47124 3.8047L4.94265 7.33329H12.6665C13.0347 7.33329 13.3332 7.63177 13.3332 7.99996C13.3332 8.36815 13.0347 8.66663 12.6665 8.66663H4.94265L8.47124 12.1952C8.73159 12.4556 8.73159 12.8777 8.47124 13.138C8.21089 13.3984 7.78878 13.3984 7.52843 13.138L2.86177 8.47136C2.60142 8.21101 2.60142 7.7889 2.86177 7.52855L7.52843 2.86189C7.78878 2.60154 8.21089 2.60154 8.47124 2.86189Z"
        fill={fill}
      />
    </styled.Svg>
  );
};

export default styled(ArrowSmall);
