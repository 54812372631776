import React, { useEffect, useLayoutEffect, useState } from 'react';
import { styled } from '@glitz/react';

import { StartPage } from './StartPage';
import { SelectProductsPage } from './SelectProductsPage';
import { AssignProductsPage } from './AssignProductsPage';
import { PatientInformationPage } from './PatientInformationPage';
import { SummaryPage } from './SummaryPage';
import { EndPage } from './EndPage';

import { Copyright } from '../Shared/Copyright/Copyright';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import RecommendationToolPageModel from './Models/RecommendationToolPageModel.interface';
import { EmailFailureModal, HelpModal, StartOverModal } from './FormModals';
import { theme } from '../Theme';
import { FormFooter } from './FormFooter';
import { TopFormPanel } from './TopFormPanel';
import { ICurrentPage } from './Models/TopFormPanel';
import {
  DRTUpdatedCategory,
  DRTUpdatedProduct,
  IMouthDotsChecked,
} from './Types';
import { StyleOrStyleArray } from '@glitz/type';
import { sendRecommendation } from './Utils/sendRecommendation';
import { media } from '@glitz/core';
import {
  DrtDataLayerEventsNames,
  stepViewDataLayer,
} from '../utils/drtDataLayer.lib';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';

export const initialProductSelection = {
  color: '',
  image: '',
  name: '',
  size: '',
  selected: false,
  code: '',
};

const RecommendationToolPage = () => {
  const { languageRoute, regionName } = useAppSettingsData();
  const {
    canonicalLink,
    globalLabels,
    globalLabels: {
      helpHeader,
      helpLabel,
      nextLabel,
      helpIntro,
      videoUrl,
      helpBody,
      startOverLabel,
      startOverHeader,
      backLabel,
      cancelLabel,
      startOverDescription,
      categories,
      sendRecommendationLabel,
      clearLabel,
    },
    selectProductsPage: {
      title: selectProductsPageTitle,
      numberOfProductsLabel,
    },
    assignProductsPage: { title: assignProductsPageTitle, header, description },
    patientInstructionsPage: { title: patientInstructionsPageTitle },
    summaryPage: { title: summaryPageTitle },
    emailData,
  } = useCurrentPage<RecommendationToolPageModel>();

  const steps = [
    { title: '', action: 'startPage' },
    { title: selectProductsPageTitle, action: 'selectProductsPage' },
    { title: assignProductsPageTitle, action: 'assignProductsPage' },
    { title: patientInstructionsPageTitle, action: 'patientInfoPage' },
    { title: summaryPageTitle, action: 'summaryPage' },
    { title: '', action: 'endPage' },
  ];

  const mapDefaultProducts = () => {
    return categories.map(cat => {
      const updatedProducts = cat.products.map(product => {
        return { ...product, selected: false };
      });
      return { ...cat, products: updatedProducts, selectedAmount: 0 };
    });
  };

  const defaultMouthDots = {
    entireMouth: [],
    specificDots: Array(30).fill({
      color: '',
      code: '',
      name: '',
      hexColor: '',
    }),
  };
  const defaultMouthTeeth = Array(32).fill('');

  const [currentPage, setCurrentPage] = useState<ICurrentPage>({
    action: 'startPage',
    index: 0,
  });

  const [isHelpModalShowed, setIsHelpModalShowed] = useState<boolean>(false);
  const [isStartOverModalShowed, setIsStartOverModalShowed] = useState<boolean>(
    false
  );
  const [productsCategories, setProductsCategories] = useState<
    DRTUpdatedCategory[]
  >(mapDefaultProducts());
  const [email, setEmail] = useState<string>('');
  const [mouthDotsChecked, setMouthDotsChecked] = useState<IMouthDotsChecked>(
    defaultMouthDots
  );
  const [mouthTeeth, setMouthTeeth] = useState<string[]>(defaultMouthTeeth);
  const [SVG, setSVG] = useState<string>('');
  const [instructions, setInstructions] = useState<string>('');
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
  const [isEmailModalFailureShown, setIsEmailModalFailureShown] = useState<
    boolean
  >(false);
  const [activeProductSelection, setActiveProductSelection] = useState<
    DRTUpdatedProduct
  >(initialProductSelection);
  const isStartOrEndPage =
    currentPage.index === 0 || currentPage.index === steps.length - 1;
  const isSelectProductsPage = currentPage.index === 1;
  const isSummaryPage = currentPage.index === 4;
  const totalSelectedProducts = productsCategories.reduce(
    (total, cat) => total + cat.selectedAmount,
    0
  );
  const selectedProductAmountStartPage =
    currentPage.action === steps[1].action ? totalSelectedProducts : null;

  const handleNextStepClick = () => {
    setCurrentPage(prevState => {
      const index = prevState.index + 1;
      if (index >= steps.length) return prevState;
      return {
        action: steps[index].action,
        index: index,
      };
    });
  };

  const handleBackStepClick = () => {
    setCurrentPage(prevState => {
      const index = prevState.index - 1;
      if (index <= 0) return prevState;
      return {
        action: steps[index].action,
        index: index,
      };
    });
  };

  const handleStartOverClick = () => {
    setActiveProductSelection(initialProductSelection);
    setCurrentPage({ action: steps[0].action, index: 0 });
    setEmail('');
    setIsEmailValid(false);
    setInstructions('');
    setSVG('');
    setProductsCategories(mapDefaultProducts());
    setMouthTeeth(defaultMouthTeeth);
    setMouthDotsChecked(defaultMouthDots);
    setIsStartOverModalShowed(false);
  };

  const handleSendEarlyRecommendation = () => {
    setCurrentPage({ action: steps[4].action, index: 4 });
  };

  const handleSendRecommendation = (): Promise<Response> => {
    return sendRecommendation(
      productsCategories,
      email,
      globalLabels,
      emailData,
      mouthDotsChecked,
      SVG,
      `${canonicalLink}SendRecommendation`,
      instructions
    );
  };

  const handleStartRecommending = () => {
    stepViewDataLayer(
      DrtDataLayerEventsNames.START_RECOMMENDING,
      languageRoute,
      regionName
    );
    handleNextStepClick();
  };

  useLayoutEffect(() => {
    if (
      mouthDotsChecked.specificDots.every(
        dot =>
          dot.code.length && dot.code === mouthDotsChecked.specificDots[0].code
      )
    ) {
      setMouthDotsChecked(prevState => {
        const updatedState = { ...prevState };
        const isNotPushed = updatedState.entireMouth.every(
          item => item.code !== updatedState.specificDots[0].code
        );
        if (isNotPushed) {
          updatedState.entireMouth.push(updatedState.specificDots[0]);
        }
        updatedState.specificDots = defaultMouthDots.specificDots;
        return updatedState;
      });
    }
  }, [defaultMouthDots.specificDots, mouthDotsChecked.specificDots]);

  useEffect(() => {
    productsCategories.forEach(cat => {
      cat.products.forEach(p => {
        if (!p.selected) {
          setMouthDotsChecked(prevState => ({
            specificDots: prevState.specificDots.map(dot =>
              dot.code === p.code
                ? { color: '', code: '', name: '', hexColor: '' }
                : dot
            ),
            entireMouth: prevState.entireMouth.filter(
              item => item.code !== p.code
            ),
          }));
          if (
            activeProductSelection &&
            activeProductSelection.code === p.code
          ) {
            setActiveProductSelection(initialProductSelection);
          }
        }
      });
    });
  }, [activeProductSelection, productsCategories]);

  const renderView = (name: string) => {
    switch (name) {
      case 'startPage':
        return <StartPage handleStartRecommending={handleStartRecommending} />;
      case 'selectProductsPage':
        return (
          <SelectProductsPage
            setProductsCategories={setProductsCategories}
            productsCategories={productsCategories}
          />
        );
      case 'assignProductsPage':
        return (
          <AssignProductsPage
            headerLabel={header}
            descriptionLabel={description}
            productsCategories={productsCategories}
            clearLabel={clearLabel}
            mouthDotsChecked={mouthDotsChecked}
            setMouthDotsChecked={setMouthDotsChecked}
            handleNextStepClick={handleNextStepClick}
            setActiveProductSelection={setActiveProductSelection}
            activeProductSelection={activeProductSelection}
          />
        );
      case 'patientInfoPage':
        return (
          <PatientInformationPage
            setMouthTeeth={setMouthTeeth}
            mouthTeeth={mouthTeeth}
            mouthDotsChecked={mouthDotsChecked}
            setInstructions={setInstructions}
            instructions={instructions}
          />
        );
      case 'summaryPage':
        return (
          <SummaryPage
            setEmail={setEmail}
            email={email}
            mouthDotsChecked={mouthDotsChecked}
            productCategories={productsCategories}
            mouthTeeth={mouthTeeth}
            instructions={instructions}
            setIsEmailValid={setIsEmailValid}
            setSVG={(svg: string) => {
              setSVG(svg);
            }}
          />
        );
      case 'endPage':
        return (
          <EndPage email={email} handleStartOverClick={handleStartOverClick} />
        );
    }
  };

  return (
    <Wrapper>
      {isStartOrEndPage ? (
        <>{renderView(currentPage.action)}</>
      ) : (
        <>
          {isHelpModalShowed && (
            <HelpModal
              toggleModal={() => setIsHelpModalShowed(false)}
              header={helpHeader}
              intro={helpIntro}
              videoUrl={videoUrl}
              contentBody={helpBody}
            />
          )}
          {isStartOverModalShowed && (
            <StartOverModal
              startOverLabel={startOverLabel}
              toggleModal={() => setIsStartOverModalShowed(false)}
              onStartOverClick={handleStartOverClick}
              title={startOverHeader}
              mainText={startOverDescription}
              cancelButtonText={cancelLabel}
            />
          )}
          {isEmailModalFailureShown && (
            <EmailFailureModal
              heading={emailData.emailFailureHeader}
              mainText={emailData.emailFailureDescription}
              closeButtonLabel={emailData.okLabel}
              toggleModal={() => setIsEmailModalFailureShown(false)}
            />
          )}
          <TopFormPanel
            helpLabel={helpLabel}
            startOverLabel={startOverLabel}
            steps={steps}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setIsHelpModalShowed={setIsHelpModalShowed}
            setIsStartOverModalShowed={setIsStartOverModalShowed}
            totalSelectedProducts={selectedProductAmountStartPage}
          />

          <MainArea>
            <Content css={isSelectProductsPage ? contentWithPaddingStyle : {}}>
              {renderView(currentPage.action)}
            </Content>
            <FormFooter
              isSummaryPage={isSummaryPage}
              productsSelectedLabel={numberOfProductsLabel}
              totalSelectedProducts={selectedProductAmountStartPage}
              backLabel={backLabel}
              nextLabel={nextLabel}
              selectedProductsAmount={totalSelectedProducts}
              productsCategories={productsCategories}
              sendRecommendationLabel={sendRecommendationLabel}
              handleNextStepClick={handleNextStepClick}
              handleBackStepClick={handleBackStepClick}
              handleSendPartlyRecommendation={handleSendEarlyRecommendation}
              handleSendRecommendation={handleSendRecommendation}
              isSendRecommendationDisabled={!email.length}
              startOverLabel={startOverLabel}
              setIsStartOverModalShowed={setIsStartOverModalShowed}
              isEmailValid={isEmailValid}
              setIsEmailModalFailureShown={setIsEmailModalFailureShown}
            />
          </MainArea>
          <CopyrightSpan />
        </>
      )}
    </Wrapper>
  );
};

const CopyrightSpan = styled(Copyright, {
  position: 'relative',
  width: '80%',
  font: { size: theme.tau, weight: theme.fontWeight.normal },
  color: 'white',
});

const Wrapper = styled.div({
  backgroundImage:
    'linear-gradient(350.67deg, rgba(79, 193, 217, 0.8) 31.3%, rgba(177, 231, 243, 0.8) 86.91%), linear-gradient(0deg, #EEF9FB, #EEF9FB)',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: `${theme.spacing(6)}px`,
});

const MainArea = styled.div({
  backgroundColor: theme.white,
  borderRadius: theme.spacing(3),
  width: '80%',
  height: '80%',
  overflow: 'hidden',
  position: 'relative',
  ...media(theme.mediaQuery.mediaMaxLarge, {
    width: '95%',
  }),
});

const Content = styled.div({
  height: 'calc(100% - 92px)',
  overflowY: 'auto',
});

const contentWithPaddingStyle: StyleOrStyleArray = {
  padding: {
    xy: theme.spacing(10),
  },
};

export default RecommendationToolPage;
