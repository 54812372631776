import React, { FC, MouseEvent } from 'react';
import { styled } from '@glitz/react';

import { IMouthImageButtons } from '../Models/MouthImage.interface';
import { theme } from '../../Theme';
import { onMouthAreaClick } from './mouthImageHelper';
import HoverPoint from '../../Shared/Icons/HoverPoint';
import { Style } from '@glitz/type';
import useCurrentPage from '../../Shared/Hooks/useCurrentPage';
import RecommendationToolPageModel from '../Models/RecommendationToolPageModel.interface';
import { colorSelectionFallback } from '../AssignProductsPage';
import { colors } from '../Types';

const MouthImageButtons: FC<IMouthImageButtons> = ({
  productColorSelection,
  setMouthDotsChecked,
  entireMouthSelection,
  mouthTeethLength,
}) => {
  const {
    globalLabels: { entireMouthLabel },
    assignProductsPage: {
      applyToEntireMouthLabel,
      selectLowerTeethLabel,
      selectUpperTeethLabel,
    },
  } = useCurrentPage<RecommendationToolPageModel>();
  const onClick = (e: MouseEvent<HTMLButtonElement>, areaType: string) => {
    e.stopPropagation();
    if (
      entireMouthSelection.some(
        item => item.code === productColorSelection?.code
      )
    ) {
      return;
    }
    onMouthAreaClick(areaType, setMouthDotsChecked!, productColorSelection);
  };

  const onEntireMouthClick = () => {
    setMouthDotsChecked?.(prevState => {
      const updatedState = { ...prevState };
      const isNotPushed =
        !!productColorSelection &&
        updatedState.entireMouth.every(
          item => item.code !== productColorSelection.code
        );

      if (isNotPushed && productColorSelection) {
        updatedState.entireMouth.push({
          code: productColorSelection?.code!,
          color: productColorSelection?.color!,
          name: productColorSelection?.name!,
          hexColor:
            colors[productColorSelection.color ?? colorSelectionFallback],
        });
      }

      updatedState.specificDots = updatedState.specificDots.map(dot =>
        dot.code === productColorSelection?.code
          ? { code: '', color: '', name: '', hexColor: '' }
          : dot
      );

      return updatedState;
    });
  };

  return (
    <>
      {entireMouthSelection.length && (
        <EntireMouthTextWrapper
          y="270"
          height="21"
          css={{ pointerEvents: 'none' }}
        >
          <EntireMouthText>{entireMouthLabel}</EntireMouthText>
        </EntireMouthTextWrapper>
      )}
      <SelectionsContainer y="290">
        <SelectionsWrapper>
          {entireMouthSelection.map((sel, i) => (
            <SelectionPoint
              fill={sel.hexColor ?? sel.color}
              css={i >= 1 ? selectionPointAlignedStyle : {}}
              title={sel.name}
            ></SelectionPoint>
          ))}
        </SelectionsWrapper>
      </SelectionsContainer>
      {!mouthTeethLength && (
        <>
          <SelectAreaButtonWrapper y="1">
            <SelectAreaButtonWrapperDiv>
              <SelectAreaButton onClick={e => onClick(e, 'Upper')}>
                {selectUpperTeethLabel}
              </SelectAreaButton>
            </SelectAreaButtonWrapperDiv>
          </SelectAreaButtonWrapper>
          <SelectAreaButtonWrapper y="555">
            <SelectAreaButtonWrapperDiv>
              <SelectAreaButton onClick={e => onClick(e, 'Lower')}>
                {selectLowerTeethLabel}
              </SelectAreaButton>
            </SelectAreaButtonWrapperDiv>
          </SelectAreaButtonWrapper>
          <SelectAreaButtonWrapper y="332" x="110" css={{ width: '51%' }}>
            <SelectAreaButtonWrapperDiv>
              <SelectAreaButton onClick={onEntireMouthClick}>
                {applyToEntireMouthLabel}
              </SelectAreaButton>
            </SelectAreaButtonWrapperDiv>
          </SelectAreaButtonWrapper>
        </>
      )}
      <g clipPath="url(#clip0_2561_28220)">
        <path
          d="M28.0528 308H25.7928C25.6528 308 25.5328 307.94 25.4528 307.8L21.9528 302.78H20.1728V307.7C20.1728 307.9 20.0728 308 19.8928 308H18.0528C17.8728 308 17.7728 307.9 17.7728 307.7V294C17.7728 293.8 17.8728 293.7 18.0528 293.7H22.6928C25.9728 293.7 27.8928 295.4 27.8928 298.26C27.8928 300.46 26.6728 301.96 24.5728 302.52L28.2328 307.64C28.3728 307.86 28.2728 308 28.0528 308ZM22.6928 295.92H20.1728V300.62H22.6928C24.5528 300.62 25.4328 299.88 25.4328 298.28C25.4328 296.66 24.5528 295.92 22.6928 295.92Z"
          fill="#60B0BD"
        />
        <path
          d="M432.196 308H424.176C423.996 308 423.896 307.9 423.896 307.7V294C423.896 293.8 423.996 293.7 424.176 293.7H426.036C426.216 293.7 426.316 293.8 426.316 294V305.78H432.196C432.396 305.78 432.496 305.88 432.496 306.06V307.7C432.496 307.9 432.396 308 432.196 308Z"
          fill="#60B0BD"
        />
      </g>
      <defs>
        <clipPath id="clip0_2561_28220">
          <rect
            width="417"
            height="28"
            fill="white"
            transform="translate(16 286)"
          />
        </clipPath>
      </defs>
    </>
  );
};

const SelectionPoint = styled(HoverPoint, {
  width: '55px',
  height: '54px',
});

const selectionPointAlignedStyle: Style = {
  marginLeft: '-35px',
};

const SelectionsWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
});

const EntireMouthText = styled.span({
  color: theme.thinBlue,
  fontSize: theme.beta,
  fontWeight: theme.fontWeight.bolder,
  lineHeight: theme.lineHeight.normal,
});

const EntireMouthTextWrapper = styled.foreignObject({
  textAlign: 'center',
  width: '100%',
});

const SelectAreaButton = styled.button({
  padding: {
    x: theme.spacing(4),
    y: theme.spacing(2),
  },
  borderRadius: theme.spacing(4),
  border: {
    xy: {
      width: theme.spacing(0.25),
      style: 'solid',
      color: theme.loadMoreBar,
    },
  },
  color: theme.thinBlue,
  backgroundColor: theme.fairBlue,
  fontWeight: theme.fontWeight.bold,
  fontSize: theme.beta,
  lineHeight: theme.lineHeight.mediumTight,
  ':hover': {
    backgroundColor: theme.aquaBlue,
  },
});

const SelectAreaButtonWrapper = styled.foreignObject({
  width: '100%',
  height: theme.spacing(13),
});

const SelectAreaButtonWrapperDiv = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
});

const SelectionsContainer = styled.foreignObject({
  width: '100%',
  height: '54px',
});

export default MouthImageButtons;
