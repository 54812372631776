import React, { FC, MouseEvent, useEffect } from 'react';
import { styled } from '@glitz/react';

import MouthModel from './MouthModel/MouthModel';
import { theme } from '../Theme';
import { IAssignProductsPage } from './Models/AssignProductsPage';

import { Style, StyleOrStyleArray } from '@glitz/type';
import DotCheckMark from '../Shared/Icons/DotCheckMark';
import { DRTUpdatedProduct, colors } from './Types';
import {
  RightBlockWrapper,
  TeethSelected as Size,
} from './PatientInformationPage';
import { initialProductSelection } from './RecommendationToolPage';
import {
  DrtDataLayerEventsNames,
  stepViewDataLayer,
} from '../utils/drtDataLayer.lib';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';

export const colorSelectionFallback = 'brown';
const colorMixedPackValue = 'mixed pack';

export const AssignProductsPage: FC<IAssignProductsPage> = ({
  headerLabel,
  descriptionLabel,
  productsCategories,
  clearLabel,
  mouthDotsChecked,
  setMouthDotsChecked,
  handleNextStepClick,
  setActiveProductSelection,
  activeProductSelection,
}) => {
  const { languageRoute, regionName } = useAppSettingsData();

  const descriptionParts = descriptionLabel?.split(/\{([^}]+)\}/g);
  const descriptionText = descriptionParts?.map((part, i) =>
    i % 2 === 0 ? (
      part
    ) : (
      <SkipStep onClick={handleNextStepClick}>{part}</SkipStep>
    )
  );

  const handleClearClick = (
    e: MouseEvent<HTMLButtonElement>,
    product: DRTUpdatedProduct
  ) => {
    e.stopPropagation();
    setMouthDotsChecked(prevState => ({
      entireMouth: prevState.entireMouth.filter(
        value => value.code !== product.code
      ),
      specificDots: prevState.specificDots.map(value => ({
        color: value.code === product.code ? '' : value.color,
        code: value.code === product.code ? '' : value.code,
        name: value.code === product.code ? '' : value.name,
        hexColor: value.code === product.code ? '' : value.hexColor,
      })),
    }));
    setActiveProductSelection(initialProductSelection);
  };

  const handleSetColor = (product: DRTUpdatedProduct) => {
    setActiveProductSelection(() => ({
      ...product,
      code: product.code,
      color: product.color ?? colorSelectionFallback,
    }));
  };

  useEffect(() => {
    stepViewDataLayer(
      DrtDataLayerEventsNames.ASSIGN_PRODUCT_VIEW,
      languageRoute,
      regionName
    );
  }, []);

  return (
    <Wrapper>
      <MainContent>
        <Header>{headerLabel}</Header>
        <Description>{descriptionText}</Description>
        <ProductsSection>
          {productsCategories.map(
            cat =>
              cat.products.some(
                product =>
                  product.selected && product.color !== colorMixedPackValue
              ) && (
                <div>
                  <CategoryHeader>{cat.name}</CategoryHeader>
                  <Products>
                    {cat.products.map(
                      product =>
                        product.selected &&
                        product.color !== colorMixedPackValue && (
                          <Product
                            onClick={() => handleSetColor(product)}
                            css={
                              activeProductSelection?.name === product.name
                                ? productActiveStyle
                                : {}
                            }
                          >
                            <Color>
                              <ColorRect
                                css={{
                                  backgroundColor:
                                    colors[
                                      product.color || colorSelectionFallback
                                    ],
                                }}
                              >
                                {activeProductSelection?.name ===
                                  product.name && <DotCheckMark fill="none" />}
                              </ColorRect>
                              <ColorText>{product.name}</ColorText>
                            </Color>
                            <RightBlockWrapper
                              css={
                                product.size
                                  ? {}
                                  : rightBlockWrapperRightAlineStyle
                              }
                            >
                              {product.size && <Size>{product.size}</Size>}
                              {(mouthDotsChecked.specificDots.some(
                                dot => dot.code === product.code
                              ) ||
                                mouthDotsChecked.entireMouth.some(
                                  dot => dot.code === product.code
                                )) &&
                                clearLabel && (
                                  <ClearButton
                                    onClick={e => handleClearClick(e, product)}
                                  >
                                    {clearLabel}
                                  </ClearButton>
                                )}
                            </RightBlockWrapper>
                          </Product>
                        )
                    )}
                  </Products>
                </div>
              )
          )}
        </ProductsSection>
      </MainContent>
      <MouthModelWrapper>
        <MouthModel
          productColorSelection={activeProductSelection}
          mouthDotsChecked={mouthDotsChecked}
          setMouthDotsChecked={setMouthDotsChecked}
          isAssignProductsPage
        />
      </MouthModelWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div({
  display: 'flex',
  height: '100%',
});

const MainContent = styled.div({
  padding: { xy: theme.spacing(10) },
  width: '50%',
  overflowY: 'auto',
});

const Header = styled.span({
  color: theme.blackAndWhiteNearBlack,
  fontSize: theme.delta,
  fontWeight: theme.fontWeight.bold,
  lineHeight: theme.lineHeight.normal,
  marginBottom: theme.spacing(2),
});

export const MouthModelWrapper = styled.div({
  width: '50%',
  height: '100%',
});

const Description = styled.p({
  marginBottom: theme.spacing(8),
  color: theme.blackAndWhiteNearBlack,
  lineHeight: theme.lineHeight.normal,
  fontSize: theme.beta,
});

const rightBlockWrapperRightAlineStyle: Style = {
  justifyContent: 'end',
};

const CategoryHeader = styled.span({
  color: theme.blackAndWhiteNearBlack,
  fontSize: theme.gamma,
  fontWeight: theme.fontWeight.bolder,
  lineHeight: theme.lineHeight.normal,
  marginBottom: theme.spacing(2),
  display: 'inline-block',
});

const Products = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: `${theme.spacing(2)}px`,
});

export const Product = styled.div({
  cursor: 'pointer',
  borderRadius: theme.spacing(),
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  border: {
    xy: {
      width: theme.spacing(0.25),
      color: theme.fairGray,
      style: 'solid',
    },
  },
  padding: {
    y: theme.spacing(2.5),
    x: theme.spacing(3.5),
  },
  ':hover': {
    border: {
      xy: {
        width: theme.spacing(0.25),
        color: theme.loadMoreBar,
        style: 'solid',
      },
    },
  },
});

export const productActiveStyle: StyleOrStyleArray = {
  border: {
    xy: {
      width: theme.spacing(0.5),
      color: theme.loadMoreBar,
      style: 'solid',
    },
  },
  backgroundColor: theme.fairBlue,
  ':hover': {
    border: {
      xy: {
        width: theme.spacing(0.5),
        color: theme.loadMoreBar,
        style: 'solid',
      },
    },
  },
};

export const Color = styled.div({
  display: 'flex',
  gap: `${theme.spacing(3)}px`,
  alignItems: 'center',
});

export const ColorRect = styled.div({
  width: theme.spacing(4.5),
  height: theme.spacing(4.5),
  borderRadius: '50px',
});

export const ColorText = styled.span({
  color: theme.blackAndWhiteNearBlack,
  fontSize: theme.beta,
  lineHeight: theme.lineHeight.normal,
});

const ProductsSection = styled.div({
  display: 'flex',
  gap: `${theme.spacing(5)}px`,
  flexDirection: 'column',
});

export const ClearButton = styled.button({
  color: theme.blueDark,

  fontSize: theme.alpha,
  fontWeight: theme.fontWeight.bold,
  lineHeight: theme.lineHeight.normal,
});

const SkipStep = styled.button({
  textDecoration: 'underline',
  color: theme.blueDark,
  lineHeight: theme.lineHeight.normal,
  fontSize: theme.beta,
});
