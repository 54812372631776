import {
  tepeBlue,
  tepeGreen,
  tepeGrey,
  tepeOrange,
  tepePink,
  tepePurple,
  tepeRed,
  tepeYellow,
} from '../Theme/colors';
import DRTCategory from './Models/DRTCategory.interface';
import DRTProduct from './Models/DRTProduct.interface';

export interface DRTUpdatedCategory extends DRTCategory {
  products: DRTUpdatedProduct[];
  selectedAmount: number;
}

export interface DRTUpdatedProduct extends DRTProduct {
  selected: boolean;
}

export interface IMouthDotsChecked {
  entireMouth: IDotColor[];
  specificDots: IDotColor[];
}

export interface IDotColor {
  code: string;
  color: string;
  hexColor?: string;
  name: string;
}
export const colors: { [key: string]: string } = {
  pink: tepePink,
  orange: tepeOrange,
  red: tepeRed,
  yellow: tepeYellow,
  purple: tepePurple,
  blue: tepeBlue,
  green: tepeGreen,
  grey: tepeGrey,
  black: '#000000',
  brown: '#A67C52',
};
