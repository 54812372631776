import { pathIdSeparator } from './mouthImageData';

import { OnMouthAreaClick } from '../Models/MouthImage.interface';
import { Dispatch, SetStateAction } from 'react';
import { IDotColor, IMouthDotsChecked, colors } from '../Types';
import { colorSelectionFallback } from '../AssignProductsPage';

export const handleTooth = (
  target: SVGGElement,
  teethClickType: string,
  setMouthTeeth?: Dispatch<SetStateAction<string[]>>
) => {
  setMouthTeeth?.(prevState => {
    const index = parseInt(target.id.split(pathIdSeparator)[1]);
    const newValue = prevState[index] === teethClickType ? '' : teethClickType;
    return [
      ...prevState.slice(0, index),
      newValue,
      ...prevState.slice(index + 1),
    ];
  });
};

export const handleDot = (
  productColorSelection: IDotColor,
  setMouthDotsChecked: Dispatch<SetStateAction<IMouthDotsChecked>>,
  target: SVGGElement
) => {
  setMouthDotsChecked?.(prevState => {
    const updatedState = [...prevState.specificDots];
    const dotIndex = parseInt(target.id.split('_')[1]);
    const dot = updatedState[dotIndex];
    if (productColorSelection.color) {
      updatedState[dotIndex] = {
        code:
          dot.code === productColorSelection.code
            ? ''
            : productColorSelection.code,
        color:
          dot.code === productColorSelection.code
            ? ''
            : productColorSelection.color ?? colorSelectionFallback,
        name:
          dot.code === productColorSelection.code
            ? ''
            : productColorSelection.name,
        hexColor:
          dot.code === productColorSelection.code
            ? ''
            : colors[productColorSelection.color ?? colorSelectionFallback],
      };
    }
    return {
      entireMouth: prevState.entireMouth,
      specificDots: updatedState,
    };
  });
};

export const onMouthAreaClick: OnMouthAreaClick = (
  areaType,
  setMouthDotsChecked,
  productColorSelection
) => {
  setMouthDotsChecked?.(prevState => {
    if (!productColorSelection?.color) return prevState;

    const updatedState = { ...prevState };
    const halfLength = Math.floor(updatedState.specificDots.length / 2);

    let startIndex = 0;
    let endIndex = updatedState.specificDots.length;

    if (areaType === 'Upper') {
      endIndex = halfLength;
    } else if (areaType === 'Lower') {
      startIndex = halfLength;
    }

    for (let i = startIndex; i < endIndex; i++) {
      if (!updatedState.specificDots[i].color) {
        updatedState.specificDots[i] = {
          code: productColorSelection.code,
          color: productColorSelection?.color,
          name: productColorSelection?.name,
          hexColor:
            colors[productColorSelection?.color ?? colorSelectionFallback],
        };
      }
    }

    return updatedState;
  });
};

export const isTooth = (target: SVGGElement) => target.id.includes('Body');

export const isDot = (target: SVGGElement) => target.id.includes('Vector');
