import React from 'react';
import { theme } from '../../Theme';
import { styled, StyledProps } from '@glitz/react';

export const Copyright = function({ compose }: StyledProps) {
  const currentYear = new Date().getFullYear();
  return (
    <Base css={compose()}>
      Copyright {currentYear}, Tepe. All rights reserved.
    </Base>
  );
};

const Base = styled.span({
  font: { weight: theme.fontWeight.normal },
  color: 'white',
});
