import RecommendationToolPageModel from './Models/RecommendationToolPageModel.interface';
import React, { FC, useEffect } from 'react';
import { VariationButton } from '../Shared/Button/Button';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import { Logo, ToothSentIcon } from '../Shared/Icons';
import RotateCircle from '../Shared/Icons/RotateCircle';
import { styled, theme } from '../Theme';
import KexLink from '../Shared/KexLink/KexLink';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import {
  DrtDataLayerEventsNames,
  stepViewDataLayer,
} from '../utils/drtDataLayer.lib';

type EndPageProps = {
  email: string;
  handleStartOverClick: () => void;
};

export const EndPage: FC<EndPageProps> = ({ email, handleStartOverClick }) => {
  const { globalLabels, endPage } = useCurrentPage<
    RecommendationToolPageModel
  >();
  const { siteRoute, languageRoute, regionName } = useAppSettingsData();

  useEffect(() => {
    stepViewDataLayer(
      DrtDataLayerEventsNames.END_PAGE_VIEW,
      languageRoute,
      regionName
    );
  }, []);

  return (
    <>
      <TopPanel>
        <KexLink href={`/${siteRoute}`}>
          <TepeLogo />
        </KexLink>
      </TopPanel>
      <MainArea>
        <StyledToothSentIcon />
        <Header>{endPage.header}</Header>
        <Description>{endPage.description}</Description>
        <p>{email}</p>
        <StyledButton
          icon={<StyledRotateCircle />}
          onClick={handleStartOverClick}
        >
          {globalLabels.startOverLabel}
        </StyledButton>
      </MainArea>
    </>
  );
};

const TopPanel = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const TepeLogo = styled(Logo, {
  width: '81px',
  height: '37px',
});

const MainArea = styled.div({
  backgroundColor: theme.white,
  borderRadius: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '80%',
  width: '80%',
  overflow: 'hidden',
  position: 'relative',
});

const StyledToothSentIcon = styled(ToothSentIcon, {
  height: theme.lambda,
});

const Header = styled.p({
  color: theme.heading,
  fontSize: theme.nu,
  fontWeight: theme.fontWeight.bold,
  margin: {
    y: theme.epsilon,
  },
});

const Description = styled.p({
  color: theme.secondaryText,
});

const StyledButton = styled(VariationButton, {
  backgroundColor: theme.blueDark,
  borderRadius: theme.chi,
  color: theme.white,
  marginTop: theme.epsilon,
});

const StyledRotateCircle = styled(RotateCircle, {
  width: theme.spacing(4),
  fill: theme.white,
});
