import React, { FC } from 'react';

import {
  mouthImageLowerDotsArray,
  mouthImageTeethArray,
  mouthImageUpperDotsArray,
  pathIdSeparator,
} from './mouthImageData';
import { IDotColor, IMouthDotsChecked } from '../Types';
import useCurrentPage from '../../Shared/Hooks/useCurrentPage';
import RecommendationToolPageModel from '../Models/RecommendationToolPageModel.interface';

interface ISummaryMouthImage {
  mouthDotsChecked?: IMouthDotsChecked;
  mouthTeeth: string[];
}

export const SummaryMouthImage: FC<ISummaryMouthImage> = ({
  mouthDotsChecked,
  mouthTeeth,
}) => {
  const {
    globalLabels: { entireMouthLabel },
  } = useCurrentPage<RecommendationToolPageModel>();

  let entireMouthWidth = 0;
  if (mouthDotsChecked?.entireMouth) {
    entireMouthWidth = 18 * mouthDotsChecked?.entireMouth?.length;
    if (entireMouthWidth > 0) {
      entireMouthWidth += 38;
    }
  }
  return (
    <svg width="449" height="85%" viewBox="0 0 449 600" fill="none">
      <g id="Mouth">
        <g id="Teeth">
          {mouthImageTeethArray.map((path, index) => {
            let fill =
              mouthTeeth[index] === 'SpecialAttention'
                ? 'rgb(204, 219, 234)'
                : 'white';
            let outline =
              mouthTeeth[index] === 'MissingTeeth'
                ? 'rgb(238, 238, 238)'
                : mouthTeeth[index] === 'SpecialAttention'
                ? 'rgb(0, 95, 158)'
                : 'black';
            return (
              <g id={index.toString()}>
                <path
                  id={`Body${pathIdSeparator}${index}`}
                  d={path.d}
                  fill={fill}
                />
                <path
                  id={`Outline${pathIdSeparator}${index}`}
                  d={path.outlineD}
                  fill={outline}
                  fillRule="evenodd"
                  clipRule="evenodd"
                />
              </g>
            );
          })}
        </g>
        <g id="Upper dots">
          {mouthImageUpperDotsArray.map((path, index) => {
            return mouthDotsChecked?.specificDots?.[index].hexColor ? (
              <g id={`Dot${pathIdSeparator}${index}`}>
                <path
                  id={`Vector${pathIdSeparator}${index}`}
                  d={path}
                  fill={mouthDotsChecked?.specificDots?.[index].hexColor}
                  fillOpacity={1}
                  stroke="white"
                  strokeWidth="6px"
                  strokeLinejoin="round"
                  paintOrder="stroke"
                />
              </g>
            ) : null;
          })}
        </g>

        {entireMouthWidth > 0 && (
          <>
            <g>
              <text
                x="50%"
                y="50%"
                fill="rgb(42, 122, 135)"
                font-size="15px"
                font-weight="500"
                text-anchor="middle"
              >
                {entireMouthLabel}
              </text>
            </g>

            <g
              id="Entire mouth"
              transform={`translate(${(449 - entireMouthWidth) / 2},290)`}
            >
              {mouthDotsChecked!!.entireMouth.map(
                (dot: IDotColor, index: number) => {
                  return (
                    <g transform={`translate(${index * 18},0)`}>
                      <path
                        d="M27.467 37.4672C34.7757 37.4672 40.7006 31.5423 40.7006 24.2336C40.7006 16.9249 34.7757 11 27.467 11C20.1583 11 14.2334 16.9249 14.2334 24.2336C14.2334 31.5423 20.1583 37.4672 27.467 37.4672Z"
                        fill={dot.hexColor}
                      />
                      <path
                        d="M27.467 37.4672C34.7757 37.4672 40.7006 31.5423 40.7006 24.2336C40.7006 16.9249 34.7757 11 27.467 11C20.1583 11 14.2334 16.9249 14.2334 24.2336C14.2334 31.5423 20.1583 37.4672 27.467 37.4672Z"
                        stroke="white"
                        strokeWidth="3"
                        strokeMiterlimit="10"
                      />
                    </g>
                  );
                }
              )}
            </g>
          </>
        )}
        <g id="Lower dots">
          {mouthImageLowerDotsArray.map((path, index) => {
            const itemIndex = index + mouthImageUpperDotsArray.length;
            return mouthDotsChecked?.specificDots?.[itemIndex].hexColor ? (
              <g id={`Dot${pathIdSeparator}${itemIndex}`}>
                <path
                  id={`Vector${pathIdSeparator}${itemIndex}`}
                  d={path}
                  fill={mouthDotsChecked?.specificDots?.[itemIndex].hexColor}
                  fillOpacity={1}
                  stroke="white"
                  strokeWidth="6px"
                  strokeLinejoin="round"
                  paintOrder="stroke"
                />
              </g>
            ) : null;
          })}
        </g>
      </g>
    </svg>
  );
};
