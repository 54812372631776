import React, { FC, useState, MouseEvent, useEffect } from 'react';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import RecommendationToolPageModel from './Models/RecommendationToolPageModel.interface';
import { styled, theme } from '../Theme';
import MouthModel from './MouthModel/MouthModel';
import {
  missingTeethClickType,
  specialAttentionTeethClickType,
} from './MouthModel/mouthImageData';
import { IPatientInformationPage } from './Models/PatientInformationPage.interface';
import {
  ClearButton,
  Color,
  ColorRect,
  ColorText,
  MouthModelWrapper,
  Product,
  productActiveStyle,
} from './AssignProductsPage';
import { Style } from '@glitz/type';
import {
  DrtDataLayerEventsNames,
  stepViewDataLayer,
} from '../utils/drtDataLayer.lib';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';

export const PatientInformationPage: FC<IPatientInformationPage> = ({
  mouthTeeth,
  setMouthTeeth,
  mouthDotsChecked,
  setInstructions,
  instructions,
}) => {
  const { languageRoute, regionName } = useAppSettingsData();
  const {
    patientInstructionsPage: {
      numberSelected,
      header,
      description,
      instructionsLabel,
      messageLabel,
      specialAttentionDescription,
      specialAttentionHeader,
      markTeethLabel,
      missingTeethHeader,
      missingTeethDescription,
      markMissingTeethLabel,
    },
    globalLabels: { clearLabel },
  } = useCurrentPage<RecommendationToolPageModel>();
  const selectedMissingTeeth = mouthTeeth.filter(
    tooth => tooth === missingTeethClickType
  );
  const selectedSpecialAttentionTeeth = mouthTeeth.filter(
    tooth => tooth === specialAttentionTeethClickType
  );
  const missingTeethSelectedText = (teethLength: number) =>
    numberSelected && teethLength
      ? numberSelected.replace('{0}', teethLength.toString())
      : '';

  const [teethClickType, setTeethClickType] = useState<string>('');

  const handleClearClick = (
    e: MouseEvent<HTMLButtonElement>,
    clickType: string
  ) => {
    e.stopPropagation();
    setTeethClickType('');
    setMouthTeeth(prevState => {
      const updatedState = [...prevState];
      updatedState.forEach((item, index) => {
        if (item === clickType) {
          updatedState[index] = '';
        }
      });
      return updatedState;
    });
  };

  useEffect(() => {
    stepViewDataLayer(
      DrtDataLayerEventsNames.PATIENT_INSTRUCTIONS_VIEW,
      languageRoute,
      regionName
    );
  }, []);

  return (
    <Wrapper>
      <ContentWrapper>
        <div>
          <Heading>{header}</Heading>
          <Description>{description}</Description>
        </div>
        <div>
          <Label>{instructionsLabel}</Label>
          <TextArea
            value={instructions}
            placeholder={messageLabel}
            onChange={e => setInstructions(e.target.value)}
          />
        </div>
        <div>
          <ButtonLabel>{specialAttentionHeader}</ButtonLabel>
          <ButtonDescription>{specialAttentionDescription}</ButtonDescription>
          <Product
            onClick={() => setTeethClickType(specialAttentionTeethClickType)}
            css={
              teethClickType === specialAttentionTeethClickType
                ? productActiveStyle
                : {}
            }
          >
            <Color>
              <ColorRect css={colorRectSpecialAttentionStyle} />
              <ColorText>{markTeethLabel}</ColorText>
            </Color>
            {mouthTeeth.some(
              item => item === specialAttentionTeethClickType
            ) && (
              <RightBlockWrapper>
                <TeethSelected>
                  {missingTeethSelectedText(
                    selectedSpecialAttentionTeeth.length
                  )}
                </TeethSelected>
                <ClearButton
                  onClick={e =>
                    handleClearClick(e, specialAttentionTeethClickType)
                  }
                >
                  {clearLabel}
                </ClearButton>
              </RightBlockWrapper>
            )}
          </Product>
        </div>
        <div>
          <ButtonLabel>{missingTeethHeader}</ButtonLabel>
          <ButtonDescription>{missingTeethDescription}</ButtonDescription>
          <Product
            onClick={() => setTeethClickType(missingTeethClickType)}
            css={
              teethClickType === missingTeethClickType ? productActiveStyle : {}
            }
          >
            <Color>
              <ColorRect css={colorRectMissingStyle} />
              <ColorText>{markMissingTeethLabel}</ColorText>
            </Color>
            {mouthTeeth.some(item => item === missingTeethClickType) && (
              <RightBlockWrapper>
                <TeethSelected>
                  {missingTeethSelectedText(selectedMissingTeeth.length)}
                </TeethSelected>
                <ClearButton
                  onClick={e => handleClearClick(e, missingTeethClickType)}
                >
                  {clearLabel}
                </ClearButton>
              </RightBlockWrapper>
            )}
          </Product>
        </div>
      </ContentWrapper>
      <MouthModelWrapper>
        <MouthModel
          teethClickType={teethClickType}
          mouthTeeth={mouthTeeth}
          setMouthTeeth={setMouthTeeth}
          mouthDotsChecked={mouthDotsChecked}
        />
      </MouthModelWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div({
  display: 'flex',
  height: '100%',
});

const ContentWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: `${theme.spacing(8)}px`,
  padding: {
    xy: theme.spacing(10),
  },
  width: '50%',
  overflowY: 'auto',
});

const Heading = styled.span({
  color: theme.blackAndWhiteNearBlack,
  fontSize: theme.delta,
  fontWeight: theme.fontWeight.bold,
  lineHeight: theme.lineHeight.normal,
  marginBottom: theme.spacing(2),
});

const Label = styled.span({
  fontSize: theme.gamma,
  fontWeight: theme.fontWeight.bolder,
  lineHeight: theme.lineHeight.normal,
  marginBottom: theme.spacing(2),
});

const ButtonLabel = styled.span({
  fontSize: theme.gamma,
  fontWeight: theme.fontWeight.bolder,
  lineHeight: theme.lineHeight.normal,
  marginBottom: theme.spacing(),
});

const Description = styled.p({
  color: theme.blackAndWhiteNearBlack,
  fontSize: theme.beta,
  fontWeight: theme.fontWeight.normal,
  lineHeight: theme.lineHeight.normal,
});

const ButtonDescription = styled.p({
  color: theme.blackAndWhiteNearBlack,
  fontSize: theme.beta,
  fontWeight: theme.fontWeight.normal,
  lineHeight: theme.lineHeight.normal,
  marginBottom: theme.spacing(3),
});

const TextArea = styled.textarea({
  resize: 'none',
  border: {
    xy: {
      width: theme.tiny,
      style: 'solid',
      color: theme.fairGray,
    },
  },
  borderRadius: '5px',
  height: theme.lambda,
  width: '100%',
  padding: {
    xy: theme.spacing(2),
  },
});

export const RightBlockWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  width: '40%',
});

export const TeethSelected = styled.span({
  fontSize: theme.beta,
  lineHeight: theme.lineHeight.normal,
  color: theme.black,
});

const colorRectSpecialAttentionStyle: Style = {
  backgroundColor: theme.oceanBlue,
  border: {
    xy: {
      width: theme.spacing(0.5),

      style: 'solid',
      color: theme.brightBlue,
    },
  },
};

const colorRectMissingStyle: Style = {
  backgroundColor: theme.white,
  border: {
    xy: {
      width: theme.spacing(0.5),
      style: 'solid',
      color: theme.fairGray,
    },
  },
};
