import { pushDataLayer } from './dataLayer.lib';
import { DRTUpdatedCategory } from '../RecommendationToolPage/Types';

export enum DrtDataLayerEventsNames {
  SELECT_PRODUCT = 'step1ProductClick',
  DESELECT_PRODUCT = 'step1ProductRemove',
  SELECT_PRODUCT_VIEW = 'step1SelectProductsView',
  ASSIGN_PRODUCT_VIEW = 'step2AssignProductsView',
  PATIENT_INSTRUCTIONS_VIEW = 'step3PatientInstructionsView',
  SUMMARY_VIEW = 'step4SummaryView',
  START_RECOMMENDING = 'startRecommendingClick',
  END_PAGE_VIEW = 'recommendationSent',
  PURCHASE_EVENT = 'dr.purchase',
}

type SelectProductDataLayer = (
  category: string,
  name: string,
  code: string,
  language: string,
  country: string,
  isSelect: boolean
) => void;

type StepViewDataLayer = (
  event: string,
  language: string,
  country: string
) => void;

type PurchaseDataLayer = (
  selectedAmount: number,
  productsCategories: DRTUpdatedCategory[],
  id: string
) => void;

export const selectProductDataLayer: SelectProductDataLayer = (
  category,
  name,
  code,
  language,
  country,
  isSelect
) => {
  pushDataLayer({
    event: isSelect
      ? DrtDataLayerEventsNames.SELECT_PRODUCT
      : DrtDataLayerEventsNames.DESELECT_PRODUCT,
    productSection: category,
    productName: name,
    productId: code,
    site_language: language,
    site_country: country,
  });
};

export const stepViewDataLayer: StepViewDataLayer = (
  event,
  language,
  country
) => {
  pushDataLayer({
    event,
    site_language: language,
    site_country: country,
  });
};

export const purchaseDataLayer: PurchaseDataLayer = (
  selectedAmount,
  productsCategories,
  id
) => {
  pushDataLayer({
    event: DrtDataLayerEventsNames.PURCHASE_EVENT,
    ecommerce: {
      transaction_id: id,
      currency: 'EUR',
      value: selectedAmount,
      items: productsCategories.flatMap(item => {
        return item.products
          .filter(product => product.selected)
          .map(product => ({
            item_id: product.code,
            item_name: product.name,
            quantity: 1,
            price: 1,
          }));
      }),
    },
  });
};
