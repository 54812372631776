import Modal from '../Shared/Modal/Modal';
import React, { FC } from 'react';
import { styled } from '@glitz/react';
import Heading from '../Shared/Heading/Heading';
import { StyleOrStyleArray } from '@glitz/type';
import { theme } from '../Theme';
import { IEmailFailureModal, IStartOverModal } from './Models/StartOverModal';
import { IHelpModal } from './Models/HelpModal';
import VideoBlock from '../Blocks/VideoBlock/VideoBlock';

export const StartOverModal: FC<IStartOverModal> = ({
  startOverLabel,
  toggleModal,
  onStartOverClick,
  title,
  mainText,
  cancelButtonText,
}) => {
  return (
    <RecoToolModal
      overlay
      toggle={toggleModal}
      isUserAction
      hasCloseButton={false}
      isClosedOnOutsideClick
    >
      <ModalContent>
        {title && (
          <ModalHeading
            size={StyledHeading}
            subUnderline={false}
            title={title}
          />
        )}
        {mainText && <ModalMainText>{mainText}</ModalMainText>}
        <ModalButtons>
          {cancelButtonText && (
            <ModalButton onClick={toggleModal}>{cancelButtonText}</ModalButton>
          )}
          {startOverLabel && (
            <ModalButton onClick={onStartOverClick} css={StyledStartOverButton}>
              {startOverLabel}
            </ModalButton>
          )}
        </ModalButtons>
      </ModalContent>
    </RecoToolModal>
  );
};

export const HelpModal: FC<IHelpModal> = ({
  toggleModal,
  header,
  intro,
  videoUrl,
  contentBody,
}) => {
  return (
    <RecoToolModal
      overlay
      toggle={toggleModal}
      isUserAction
      hasCloseButton
      isClosedOnOutsideClick
      isMaxWidth={false}
      css={StyledHelpModal}
    >
      <ModalContent>
        {header && (
          <ModalHeading
            size={StyledHeading}
            subUnderline={false}
            title={header}
            css={StyledHelpHeading}
          />
        )}
        {intro && <HelpModalIntroText>{intro}</HelpModalIntroText>}
        {videoUrl && (
          <Video
            content={{
              contentVideo: {
                name: 'helpModalVideo',
                url: videoUrl,
              },
            }}
          />
        )}
        {contentBody && (
          <div dangerouslySetInnerHTML={{ __html: contentBody }} />
        )}
      </ModalContent>
    </RecoToolModal>
  );
};

export const EmailFailureModal: FC<IEmailFailureModal> = ({
  heading,
  mainText,
  closeButtonLabel,
  toggleModal,
}) => {
  return (
    <RecoToolModal
      overlay
      toggle={toggleModal}
      isUserAction
      hasCloseButton={false}
      isClosedOnOutsideClick
    >
      <ModalContent>
        {heading && (
          <ModalHeading
            size={StyledHeading}
            subUnderline={false}
            title={heading}
          />
        )}
        {mainText && <ModalMainText>{mainText}</ModalMainText>}
        <ModalButtons>
          {closeButtonLabel && (
            <ModalButton onClick={toggleModal} css={StyledStartOverButton}>
              {closeButtonLabel}
            </ModalButton>
          )}
        </ModalButtons>
      </ModalContent>
    </RecoToolModal>
  );
};

const RecoToolModal = styled(Modal, {
  alignSelf: 'center',
});

const Video = styled(VideoBlock, {
  marginBottom: theme.spacing(3),
});

const ModalHeading = styled(Heading, {
  color: theme.blueDark,
  margin: { xy: 0 },
});

const ModalMainText = styled.span({
  fontSize: theme.gamma,
  fontWeight: theme.fontWeight.normal,
  lineHeight: theme.lineHeight.normal,
  marginBottom: theme.spacing(5),
});

const ModalButtons = styled.div({
  display: 'flex',
  justifyContent: 'right',
  gap: `${theme.spacing(4)}px`,
});

const StyledHeading: StyleOrStyleArray = {
  font: { size: theme.nu, weight: theme.fontWeight.bold },
  lineHeight: theme.lineHeight.moreTight,
  margin: { xy: 0 },
  marginBottom: theme.spacing(1.5),
};

const ModalContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: { xy: theme.spacing(10) },
  maxWidth: '800px',
  maxHeight: '80vh',
  overflowY: 'auto',
  overflowX: 'hidden',
});

const ModalButton = styled.button({
  padding: { y: theme.spacing(4), x: theme.spacing(6) },
  borderRadius: theme.spacing(),
  backgroundColor: theme.white,
  color: theme.blueDark,
  fontSize: theme.beta,
  fontWeight: theme.fontWeight.bold,
  letterSpacing: `${theme.letterSpacing.medium}px`,
  textTransform: 'uppercase',
  border: {
    xy: {
      style: 'solid',
      width: theme.spacing(0.25),
      color: theme.blueDark,
    },
  },
});

const StyledStartOverButton: StyleOrStyleArray = {
  backgroundColor: theme.lightOrange,
  color: theme.white,
  border: {
    xy: {
      style: 'none',
    },
  },
  ':hover': {
    backgroundColor: theme.blueDark,
  },
};

const StyledHelpHeading: StyleOrStyleArray = {
  marginBottom: theme.spacing(5),
};

const StyledHelpModal: StyleOrStyleArray = {
  maxWidth: 'min(800px,80%)',
  maxHeight: 'min(800px,80%)',
  display: 'flex',
};

const HelpModalIntroText = styled.span({
  fontSize: theme.delta,
  fontWeight: theme.fontWeight.normal,
  lineHeight: theme.lineHeight.normal,
  color: theme.black,
});
