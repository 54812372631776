import React from 'react';
import { styled, StyledProps } from '@glitz/react';

const AlertCircle = ({ compose }: StyledProps) => {
  return (
    <styled.Svg
      css={compose()}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17 17"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.49984 2.50008C5.18613 2.50008 2.49984 5.18637 2.49984 8.50008C2.49984 11.8138 5.18613 14.5001 8.49984 14.5001C11.8135 14.5001 14.4998 11.8138 14.4998 8.50008C14.4998 5.18637 11.8135 2.50008 8.49984 2.50008ZM1.1665 8.50008C1.1665 4.44999 4.44975 1.16675 8.49984 1.16675C12.5499 1.16675 15.8332 4.44999 15.8332 8.50008C15.8332 12.5502 12.5499 15.8334 8.49984 15.8334C4.44975 15.8334 1.1665 12.5502 1.1665 8.50008ZM8.49984 5.16675C8.86803 5.16675 9.1665 5.46522 9.1665 5.83341V8.50008C9.1665 8.86827 8.86803 9.16675 8.49984 9.16675C8.13165 9.16675 7.83317 8.86827 7.83317 8.50008V5.83341C7.83317 5.46522 8.13165 5.16675 8.49984 5.16675ZM7.83317 11.1667C7.83317 10.7986 8.13165 10.5001 8.49984 10.5001H8.5065C8.87469 10.5001 9.17317 10.7986 9.17317 11.1667C9.17317 11.5349 8.87469 11.8334 8.5065 11.8334H8.49984C8.13165 11.8334 7.83317 11.5349 7.83317 11.1667Z"
        fill="#004C97"
      />
    </styled.Svg>
  );
};

export default styled(AlertCircle);
