import React from 'react';
import { styled, StyledProps } from '@glitz/react';

interface ISmallArrowRightIcon {
  fill: string;
  title?: string;
}

const HoverPoint = ({
  compose,
  fill,
  title,
}: ISmallArrowRightIcon & StyledProps) => {
  return (
    <styled.Svg
      css={compose()}
      viewBox="0 0 55 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Hoverpoint">
        <g id="Vector" filter="url(#filter0_d_3045_5511)">
          <path
            d="M27.467 37.4672C34.7757 37.4672 40.7006 31.5423 40.7006 24.2336C40.7006 16.9249 34.7757 11 27.467 11C20.1583 11 14.2334 16.9249 14.2334 24.2336C14.2334 31.5423 20.1583 37.4672 27.467 37.4672Z"
            fill={fill}
          />
          <path
            d="M27.467 37.4672C34.7757 37.4672 40.7006 31.5423 40.7006 24.2336C40.7006 16.9249 34.7757 11 27.467 11C20.1583 11 14.2334 16.9249 14.2334 24.2336C14.2334 31.5423 20.1583 37.4672 27.467 37.4672Z"
            stroke="white"
            strokeWidth="3"
            strokeMiterlimit="10"
          />
          {title && <title>{title}</title>}
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_3045_5511"
          x="0.733398"
          y="0.5"
          width="53.4673"
          height="53.4673"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="6" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3045_5511"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3045_5511"
            result="shape"
          />
        </filter>
      </defs>
    </styled.Svg>
  );
};

export default styled(HoverPoint);
