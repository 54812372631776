import React, { FC, useState } from 'react';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import RecommendationToolPageModel from './Models/RecommendationToolPageModel.interface';
import KexLink from '../Shared/KexLink/KexLink';
import { Button } from '../Shared/Button/Button';
import { styled, theme } from '../Theme';
import { media } from '@glitz/core';
import { StyleOrStyleArray } from '@glitz/type';
import backgroundImage from './Assets/brushes-fade.png';
import { ChevronIcon, Logo } from '../Shared/Icons';
import AlternativeLinkModel from './Models/AlternativeLinkModel.interface';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import { Copyright } from '../Shared/Copyright/Copyright';

interface IStartPage {
  handleStartRecommending: () => void;
}

export const StartPage: FC<IStartPage> = ({ handleStartRecommending }) => {
  const [list, setList] = useState<AlternativeLinkModel[] | null>(null);
  const [isListOpen, setIsListOpen] = useState(false);
  const { startPage, recommendationToolLinks } = useCurrentPage<
    RecommendationToolPageModel
  >();
  const { siteRoute } = useAppSettingsData();
  let windowRoute = '/global/recommendation-tool';
  if (typeof window !== 'undefined') {
    windowRoute = window?.location?.pathname;
  }
  const currentRoute = recommendationToolLinks.filter(link =>
    link.link.includes(windowRoute)
  );

  const showValue = () => {
    setList(recommendationToolLinks);
    setIsListOpen(!isListOpen);
  };

  return (
    <Container>
      <ContentContainer>
        <KexLink href={`/${siteRoute}`}>
          <Logo css={TepeLogo} />
        </KexLink>
        <Title>{startPage.header}</Title>
        <SubTitle>{startPage.description}</SubTitle>
        <DropDown onClick={showValue}>
          <DropDownLabelWrapper>
            <DropDownLabel>
              <DropDownSpan>{currentRoute.map(link => link.name)}</DropDownSpan>
            </DropDownLabel>
            <DropDownButtonWrapper>
              <DropDownButton>
                <DropDownArrow
                  css={{
                    transform: isListOpen ? 'rotate(180deg)' : 'none',
                  }}
                />
              </DropDownButton>
            </DropDownButtonWrapper>
          </DropDownLabelWrapper>
          {list && isListOpen && (
            <DropDownListWrapper>
              {recommendationToolLinks.length < 1 ? (
                <LightColorSpan>No other options available.</LightColorSpan>
              ) : (
                <DropDownList>
                  {recommendationToolLinks.map(link => (
                    <KexLink href={link.link} fullPageReload>
                      <DropDownListItem key={link.link}>
                        <DropDownListButton>{link.name}</DropDownListButton>
                      </DropDownListItem>
                    </KexLink>
                  ))}
                </DropDownList>
              )}
            </DropDownListWrapper>
          )}
        </DropDown>
        <StartButton css={PrimaryButton} onClick={handleStartRecommending}>
          {startPage.startRecommendationLabel}
        </StartButton>
      </ContentContainer>

      <CopyrightSpan />
    </Container>
  );
};

const CopyrightSpan = styled(Copyright, {
  position: 'absolute',
  left: theme.spacing(30),
  bottom: theme.spacing(6),
  font: { size: theme.beta },
});

const Container = styled.div({
  backgroundImage: `url(${backgroundImage})`,
  backgroundRepeat: 'no-repeat',
  minHeight: '100vh',
  width: '100vw',
  backgroundPosition: 'right bottom',
  overflow: 'hidden clip',
});

const ContentContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  width: '80vw',
  height: '100vh',
  padding: { x: theme.spacing(10) },
  ...media(theme.mediaQuery.mediaMinLarge, {
    maxWidth: '40vw',
    padding: { x: theme.spacing(30) },
  }),
});

const DropDown = styled.div({
  font: { size: theme.beta, weight: theme.fontWeight.normal },
  paddingBottom: theme.spacing(5),
});

const DropDownArrow = styled(ChevronIcon, {
  height: theme.spacing(3),
  width: theme.spacing(3),
  color: theme.black,
  transition: {
    duration: theme.timings.oneThird,
    property: 'all',
  },
  margin: { xy: 0 },
});

const DropDownButton = styled.button({
  backgroundColor: theme.white,
  color: theme.blueDark,
});

const DropDownButtonWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: theme.spacing(5),
  width: theme.spacing(5),
  ':hover': {
    borderRadius: theme.spacing(5),
    border: {
      xy: {
        style: 'solid',
        width: theme.tiny,
        color: theme.lightGray,
      },
    },
    transform: 'scale(1.25)',
  },
});

const DropDownLabel = styled.label({
  font: { weight: theme.fontWeight.bold },
  marginLeft: theme.spacing(2.5),
});

const DropDownLabelWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: theme.white,
  width: theme.dropDownWrapper,
  height: theme.spacing(10),
  borderRadius: theme.spacing(1.5),
  padding: { x: theme.spacing(2), y: theme.spacing(3) },
  ':hover': {
    border: {
      xy: {
        style: 'solid',
        width: theme.tiny,
        color: theme.blueDark,
      },
    },
  },
});

const DropDownList = styled.ul({
  width: '100%',
});

const DropDownListButton = styled.button({
  display: 'flex',
  alignContent: 'flex-start',
  width: '100%',
  padding: { xy: theme.spacing(4) },
  ':hover': {
    backgroundColor: theme.lightBlue,
  },
});

const DropDownListItem = styled.li({});

const DropDownListWrapper = styled.div({
  position: 'absolute',
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: theme.white,
  width: theme.dropDownWrapper,
  minHeight: theme.dropDownListWrapperMinHeight,
  borderRadius: theme.spacing(1.5),
  padding: { y: theme.spacing(3) },
  boxShadow: '0px 10px 10px -10px black',
  zIndex: theme.zIndex.DropDown,
});

const DropDownSpan = styled.span({});

const LightColorSpan = styled.span({
  font: { size: theme.alpha, weight: theme.fontWeight.normal },
  color: theme.tepeGrey,
  margin: { xy: theme.spacing(2.5) },
});

const PrimaryButton: StyleOrStyleArray = {
  ':hover': {
    backgroundColor: theme.primary,
  },
};

const StartButton = styled(Button, {
  font: { size: theme.gamma, weight: theme.fontWeight.bold },
  letterSpacing: theme.letterSpacing.medium,
  backgroundColor: theme.blueDark,
  height: 'auto',
  padding: { x: theme.spacing(8), y: theme.spacing(4) },
  border: { xy: { width: theme.none } },
  color: theme.white,
  minWidth: theme.dropDownWrapper,
});

const SubTitle = styled.h4({
  color: theme.backdrop,
  font: { size: theme.beta, weight: theme.fontWeight.bold },
  paddingBottom: theme.spacing(5),
});

const TepeLogo: StyleOrStyleArray = {
  height: theme.tepeLogoHeroHeight,
  paddingBottom: theme.spacing(5),
};

const Title = styled.h1({
  color: theme.primary,
  font: { size: theme.theta, weight: theme.fontWeight.bold },
  paddingBottom: theme.spacing(5),
  ...media(theme.mediaQuery.mediaMinLarge, {
    font: { size: theme.iota, weight: theme.fontWeight.bold },
  }),
});
