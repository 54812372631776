import React, { FC, useEffect } from 'react';

import { theme } from '../Theme';
import { styled } from '@glitz/react';
import { IProductCard, ISelectProductsPage } from './Models/SelectProductsPage';
import { Style } from '@glitz/type';
import DotCheckMark from '../Shared/Icons/DotCheckMark';
import {
  DrtDataLayerEventsNames,
  selectProductDataLayer,
  stepViewDataLayer,
} from '../utils/drtDataLayer.lib';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import { DRTUpdatedProduct } from './Types';

let windowRoute = '';

if (typeof window !== 'undefined') {
  windowRoute = window?.location?.origin;
}
export const SelectProductsPage: FC<ISelectProductsPage> = ({
  setProductsCategories,
  productsCategories,
}) => {
  const { languageRoute, regionName } = useAppSettingsData();

  const handleClick = (
    categoryName: string,
    clickedProduct: DRTUpdatedProduct
  ) => {
    selectProductDataLayer(
      categoryName,
      clickedProduct.name,
      clickedProduct.code,
      languageRoute,
      regionName,
      !clickedProduct.selected
    );
    const updatedCategories = productsCategories.map(cat => {
      if (cat.name === categoryName) {
        let isSelected = false;
        const updatedProducts = cat.products.map(product => {
          if (product.name === clickedProduct.name) {
            isSelected = product.selected;
            return { ...product, selected: !product.selected };
          }
          return product;
        });
        return {
          ...cat,
          products: updatedProducts,
          selectedAmount: isSelected
            ? cat.selectedAmount - 1
            : cat.selectedAmount + 1,
        };
      }
      return cat;
    });
    setProductsCategories(updatedCategories);
  };

  useEffect(() => {
    stepViewDataLayer(
      DrtDataLayerEventsNames.SELECT_PRODUCT_VIEW,
      languageRoute,
      regionName
    );
  }, []);

  return (
    <Wrapper>
      {productsCategories.map(cat => (
        <div key={cat.name}>
          <Heading>{cat.name}</Heading>
          <Description>{cat.description}</Description>
          <ProductsWrapper>
            {cat.products.map(product => (
              <ProductCard
                key={product.code}
                product={product}
                onClick={() => handleClick(cat.name, product)}
              />
            ))}
          </ProductsWrapper>
        </div>
      ))}
    </Wrapper>
  );
};

export const ProductCard: FC<IProductCard> = ({
  product: { selected, image, name, size },
  onClick,
  isSummaryPage = false,
}) => {
  if (!image || !name) return null;
  return (
    <Product
      onClick={onClick}
      css={
        selected
          ? isSummaryPage
            ? summaryPageProductCard
            : productCardSelectedStyle
          : isSummaryPage
          ? summaryPageProductCard
          : {}
      }
    >
      {selected && <CheckMark />}
      <ProductImageWrapper
        css={
          selected
            ? productImageWrapperSelectedStyle
            : isSummaryPage
            ? productImageWrapperSummaryPageStyle
            : {}
        }
      >
        <ProductImage
          src={`${windowRoute}${image}`}
          css={isSummaryPage ? {} : productImageSelectPageStyle}
        />
      </ProductImageWrapper>
      <ProductName>{name}</ProductName>
      {isSummaryPage && size && <ProductSize>{size}</ProductSize>}
    </Product>
  );
};

const Heading = styled.span({
  fontSize: theme.gamma,
  fontWeight: theme.fontWeight.bold,
  lineHeight: theme.lineHeight.normal,
  color: theme.blackAndWhiteNearBlack,
  marginBottom: theme.spacing(),
});

const CheckMark = styled(DotCheckMark, {
  width: '19px',
  height: '18px',
  alignSelf: 'flex-start',
});

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: `${theme.spacing(8)}px`,
});

const Description = styled.p({
  color: theme.blackAndWhiteNearBlack,
  fontSize: theme.beta,
  fontWeight: theme.fontWeight.normal,
  lineHeight: theme.lineHeight.normal,
  marginBottom: theme.spacing(4),
});

const ProductsWrapper = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  gap: `${theme.spacing(4)}px`,
});

const Product = styled.div({
  cursor: 'pointer',
  width: '163px',
  minHeight: '205px',
  padding: { xy: theme.spacing(2) },
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: theme.spacing(2),
  ':hover': {
    border: {
      xy: {
        color: theme.loadMoreBar,
        width: theme.spacing(0.25),
        style: 'solid',
      },
    },
  },
  border: {
    xy: { color: theme.fairGray, width: theme.spacing(0.25), style: 'solid' },
  },
});

const ProductName = styled.span({
  color: theme.blackAndWhiteNearBlack,
  textAlign: 'center',
  fontSize: theme.beta,
  lineHeight: theme.lineHeight.normal,
});

const summaryPageProductCard: Style = {
  cursor: 'initial',
  width: '153px',
  minHeight: '182px',
  ':hover': {
    border: {
      xy: {
        color: theme.fairGray,
        width: theme.spacing(0.25),
        style: 'solid',
      },
    },
  },
};

const productImageWrapperSummaryPageStyle: Style = {
  width: '80px',
  height: '80px',
};

const productCardSelectedStyle: Style = {
  border: {
    xy: { color: theme.loadMoreBar, width: theme.spacing(0.5), style: 'solid' },
  },
  ':hover': {
    border: {
      xy: {
        color: theme.loadMoreBar,
        width: theme.spacing(0.5),
        style: 'solid',
      },
    },
  },
};

const productImageWrapperSelectedStyle: Style = {
  marginTop: 0,
};

const ProductImageWrapper = styled.div({
  width: '138px',
  height: '138px',
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(4.75),
  overflow: 'hidden',
});

const ProductImage = styled.img({
  width: '100%',
  height: '100%',
});

const productImageSelectPageStyle: Style = {
  transform: 'scale(1)',
  transition: {
    property: 'transform',
    duration: theme.timings.threeTenths,
  },
  ':hover': {
    transform: 'scale(1.25)',
    transition: {
      property: 'transform',
      duration: theme.timings.threeTenths,
    },
  },
};

const ProductSize = styled.span({
  color: theme.blackAndWhiteNearBlack,
  fontSize: theme.alpha,
  textAlign: 'center',
});
