import React, { FC } from 'react';
import { styled } from '@glitz/react';
import { theme } from '../../Theme';

import MouthImage from './MouthImage';

import { Copyright } from '../../Shared/Copyright/Copyright';
import { IMouthModel } from '../Models/MouthImage.interface';

const MouthModel: FC<IMouthModel> = ({
  productColorSelection,
  mouthDotsChecked,
  setMouthDotsChecked,
  isAssignProductsPage = false,
  teethClickType,
  mouthTeeth,
  setMouthTeeth,
}) => {
  return (
    <>
      <MouthImageWrapper>
        <MouthImage
          productColorSelection={productColorSelection}
          teethClickType={teethClickType}
          mouthDotsChecked={mouthDotsChecked}
          setMouthDotsChecked={setMouthDotsChecked}
          isAssignProductsPage={isAssignProductsPage}
          exportSVG={false}
          mouthTeeth={mouthTeeth}
          setMouthTeeth={setMouthTeeth}
        />
        <CopyrightWrapper>
          <CopyrightSpan />
        </CopyrightWrapper>
      </MouthImageWrapper>
    </>
  );
};

const CopyrightWrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-start',
  width: '100%',
});

const CopyrightSpan = styled(Copyright, {
  padding: {
    x: theme.spacing(6),
  },
  font: { size: theme.tau, weight: theme.fontWeight.normal },
  color: theme.thinBlue,
});

export const MouthImageWrapper = styled.div({
  backgroundImage: `url(
    "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25'%3E%3Cdefs%3E%3Cpattern id='bg' patternUnits='userSpaceOnUse' width='16' height='16'%3E%3Crect width='16' height='16' fill='%23e0f1f5' /%3E%3Cline x1='0' x2='16' y1='8' y2='8' stroke='%23c2e5ea'/%3E%3Cline x1='8' x2='8' y1='0' y2='16' stroke='%23c2e5ea'/%3E%3C/pattern%3E%3C/defs%3E%3Crect width='100%25' height='100%25' fill='url(%23bg)'/%3E%3C/svg%3E"
  )`,
  backgroundRepeat: 'repeat',
  height: '100%',
  gap: `${theme.spacing(4)}px`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

export default MouthModel;
