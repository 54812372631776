import React, { FC, useEffect, useRef } from 'react';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import RecommendationToolPageModel from './Models/RecommendationToolPageModel.interface';
import KexInput from '../Shared/Input/KexInput';
import { Copyright } from '../Shared/Copyright/Copyright';
import { KexInputValidation } from '../Shared/Input/KexInputValidation';
import { ISummaryPage } from './Models/SelectProductsPage';
import { ProductCard } from './SelectProductsPage';
import { styled, theme } from '../Theme';
import { SummaryMouthImage } from './MouthModel/SummaryMouthImage';
import { MouthImageWrapper } from './MouthModel';
import { Style } from '@glitz/type';
import {
  DrtDataLayerEventsNames,
  stepViewDataLayer,
} from '../utils/drtDataLayer.lib';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';

export const SummaryPage: FC<ISummaryPage> = ({
  setEmail,
  email,
  productCategories,
  mouthDotsChecked,
  setSVG,
  mouthTeeth,
  instructions,
  setIsEmailValid,
}) => {
  const { languageRoute, regionName } = useAppSettingsData();
  const {
    summaryPage: {
      header,
      description,
      patientEmailHeader,
      missingTeethLabel,
      instructionsLabel,
      specialTeethHeader,
      specialAttentionLabel,
      recommendedProductsLabel,
      emailLabel,
    },
  } = useCurrentPage<RecommendationToolPageModel>();
  const mouthRef = useRef<HTMLDivElement>(null);
  const validationPattern = /\S+@\S+\.\S+/;

  const styledDescription = description.replace(
    /<a /g,
    `<a style="${`text-decoration: underline; color: ${theme.blueDark}`.replace(
      /"/g,
      "'"
    )}" `
  );

  const missingTeethCount = mouthTeeth.filter(
    (t: string) => t === 'MissingTeeth'
  ).length;
  const specialAttentionCount = mouthTeeth.filter(
    (t: string) => t === 'SpecialAttention'
  ).length;

  const onInputChange = (value: string) => {
    setEmail(value);
    setIsEmailValid(validationPattern.test(value));
  };

  useEffect(() => {
    // Retrieve the SVG from the mouth model and store it
    const node = mouthRef.current?.querySelector('svg');
    if (node) {
      setSVG(node.outerHTML);
    }
  }, []);

  useEffect(() => {
    stepViewDataLayer(
      DrtDataLayerEventsNames.SUMMARY_VIEW,
      languageRoute,
      regionName
    );
  }, []);

  return (
    <Wrapper>
      <ContentWrapper>
        <div>
          <Heading>{header}</Heading>
          <Description
            dangerouslySetInnerHTML={{ __html: styledDescription }}
          />
        </div>
        <div>
          <InputTitle>{patientEmailHeader}</InputTitle>
          <KexInputValidation>
            <KexInput
              title=""
              isDRT
              value={email}
              type="email"
              placeholder={emailLabel}
              onChange={onInputChange}
              validation={{
                required: true,
                minLength: 5,
                maxLength: 320,
                pattern: validationPattern,
                errorMessage: 'Invalid email',
              }}
            />
          </KexInputValidation>
        </div>
        {instructions && (
          <InstructionsWrapper>
            <Label css={instructionsLabelStyle}>{instructionsLabel}</Label>
            <InstructionsText>{instructions}</InstructionsText>
          </InstructionsWrapper>
        )}
        <div>
          <Label css={recommendedProductsLabelStyle}>
            {recommendedProductsLabel}
          </Label>
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            {productCategories
              .filter(cat => cat.selectedAmount > 0)
              .map((cat, index) =>
                cat.products
                  .filter(product => product.selected)
                  .map(product => (
                    <ProductCard
                      key={product.code}
                      product={{ ...product, selected: false }}
                      isSummaryPage
                    />
                  ))
              )}
          </div>
        </div>
        {missingTeethCount + specialAttentionCount > 0 && (
          <div>
            <Label css={instructionsLabelStyle}>{specialTeethHeader}</Label>
            {specialAttentionCount > 0 && (
              <SpecialTeethInstruction>
                <b>{specialAttentionCount}</b> - {specialAttentionLabel}
              </SpecialTeethInstruction>
            )}
            {missingTeethCount > 0 && (
              <SpecialTeethInstruction>
                <b>{missingTeethCount}</b> - {missingTeethLabel}
              </SpecialTeethInstruction>
            )}
          </div>
        )}
      </ContentWrapper>
      <MouthImageContainer>
        <MouthImageWrapper ref={mouthRef}>
          <SummaryMouthImage
            mouthDotsChecked={mouthDotsChecked}
            mouthTeeth={mouthTeeth}
          />
          <CopyrightWrapper>
            <CopyrightSpan />
          </CopyrightWrapper>
        </MouthImageWrapper>
      </MouthImageContainer>
    </Wrapper>
  );
};

const CopyrightWrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-start',
  width: '100%',
});

const CopyrightSpan = styled(Copyright, {
  padding: {
    x: theme.spacing(6),
  },
  font: { size: theme.tau, weight: theme.fontWeight.normal },
  color: theme.thinBlue,
});

const Wrapper = styled.div({
  display: 'flex',
  height: '100%',
  fontSize: theme.beta,
  fontWeight: theme.fontWeight.normal,
});

const ContentWrapper = styled.div({
  width: '50%',
  overflowY: 'scroll',
  display: 'flex',
  gap: `${theme.spacing(8)}px`,
  flexDirection: 'column',
  padding: {
    xy: theme.spacing(10),
  },
});

const Heading = styled.span({
  display: 'inline-block',
  color: theme.blackAndWhiteNearBlack,
  fontSize: theme.delta,
  fontWeight: theme.fontWeight.bold,
  lineHeight: theme.lineHeight.normal,
  marginBottom: theme.spacing(2),
});

const SpecialTeethInstruction = styled.div({
  fontSize: theme.beta,
  color: theme.blackAndWhiteNearBlack,
});

const InputTitle = styled.span({
  display: 'inline-block',
  color: theme.black,
  fontSize: theme.gamma,
  fontWeight: theme.fontWeight.bolder,
  lineHeight: theme.lineHeight.normal,
  marginBottom: theme.spacing(2),
});

const InstructionsWrapper = styled.div({});

const Description = styled.div({
  color: theme.blackAndWhiteNearBlack,
  fontSize: theme.beta,
  fontWeight: theme.fontWeight.normal,
  lineHeight: theme.lineHeight.normal,
});

const Label = styled.span({
  fontSize: theme.gamma,
  fontWeight: theme.fontWeight.bolder,
});

const InstructionsText = styled.p({
  fontSize: theme.beta,
  lineHeight: theme.lineHeight.normal,
});

const instructionsLabelStyle: Style = {
  display: 'inline-block',
  marginBottom: theme.spacing(),
};

const recommendedProductsLabelStyle: Style = {
  display: 'inline-block',
  marginBottom: theme.spacing(3),
};

const MouthImageContainer = styled.div({
  width: '50%',
  height: '100%',
});
