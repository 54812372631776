import EmailDataModel from '../Models/EmailDataModel.interface';
import GlobalLabelsModel from '../Models/GlobalLabelsModel.interface';
import ResellerModel from '../Models/ResellerModel.interface';
import TipsAndAdviceModel from '../Models/TipsAndAdviceModel.interface';
import {
  DRTUpdatedCategory,
  DRTUpdatedProduct,
  IDotColor,
  IMouthDotsChecked,
  colors,
} from '../Types';

const getUpperMouth = (mouthDotsChecked: IMouthDotsChecked): IDotColor[] => {
  return mouthDotsChecked.specificDots
    .slice(0, 14)
    .filter(
      (value, index, self) =>
        self.findIndex(
          color => color.color !== '' && color.color === value.color
        ) === index
    );
};

const getLowerMouth = (mouthDotsChecked: IMouthDotsChecked) => {
  return mouthDotsChecked.specificDots
    .slice(15, 30)
    .filter(
      (value, index, self) =>
        self.findIndex(
          color => color.color !== '' && color.color === value.color
        ) === index
    );
};

export const sendRecommendation = async (
  productCategories: DRTUpdatedCategory[],
  email: string,
  globalLabels: GlobalLabelsModel,
  emailData: EmailDataModel,
  mouthDotsChecked: IMouthDotsChecked,
  svg: string,
  url: string,
  instructions?: string
) => {
  let windowRoute = '';

  if (typeof window !== 'undefined') {
    windowRoute = window?.location?.origin;
  }

  const upperColors = getUpperMouth(mouthDotsChecked);
  const lowerColors = getLowerMouth(mouthDotsChecked);
  type DRTUpdatedProductRow = DRTUpdatedProduct[];
  let selectedProductsRow: DRTUpdatedProduct[] = [];
  let selectedProducts: DRTUpdatedProductRow[] = [];
  // Get the selected products and group into rows of 4
  // so that we don't have to worry about backend fixes
  productCategories
    .filter(cat => cat.selectedAmount > 0)
    .forEach(cat => {
      cat.products
        .filter(product => product.selected)
        .forEach(product => {
          selectedProductsRow.push({
            ...product,
            color: product.color ?? 'brown',
            image: `${windowRoute}${product.image}`,
          });
          if (selectedProductsRow.length === 4) {
            selectedProducts.push(selectedProductsRow);
            selectedProductsRow = [];
          }
        });
    });
  if (selectedProductsRow.length > 0) {
    selectedProducts.push(selectedProductsRow);
  }

  // Group resellers into groups with five
  type ResellerRow = ResellerModel[];
  let resellerRow: ResellerModel[] = [];

  let resellers: ResellerRow[] = [];
  emailData.resellers.forEach(reseller => {
    resellerRow.push(reseller);
    if (resellerRow.length === 5) {
      resellers.push(resellerRow);
      resellerRow = [];
    }
  });
  if (resellerRow.length > 0) {
    resellers.push(resellerRow);
  }

  // Group tips and advice into groups of 2
  type TipsAndAdviceRow = TipsAndAdviceModel[];
  let tipsAndAdviceRow: TipsAndAdviceModel[] = [];

  let tipsAndAdvice: TipsAndAdviceRow[] = [];
  emailData.tipsAndAdvice.forEach(tips => {
    tipsAndAdviceRow.push(tips);
    if (tipsAndAdviceRow.length === 2) {
      tipsAndAdvice.push(tipsAndAdviceRow);
      tipsAndAdviceRow = [];
    }
  });
  if (tipsAndAdviceRow.length > 0) {
    tipsAndAdvice.push(tipsAndAdviceRow);
  }
  const body = JSON.stringify({
    emailTo: email,
    svgMouthImageBase64: btoa(svg),
    emailBody: {
      Resellers: resellers,
      TipsAndAdvice: tipsAndAdvice,
      GlobalLabels: { ...globalLabels, categories: [] },
      Labels: {
        ...emailData,
        header: emailData.header.replace('\n', '<br/>'),
        copyrightLabel: emailData.copyrightLabel.replace('\n', '<br/>'),
        moreTipsAndTricksUrl: `${windowRoute}${emailData.moreTipsAndTricksUrl}`,
        privacyPolicyLink: `${windowRoute}${emailData.privacyPolicyLink}`,
      },
      Products: selectedProducts,
      Instructions: instructions,
      UpperMouth: upperColors.map(color => {
        return { ...color, color: color.color ?? 'brown' };
      }),
      LowerMouth: lowerColors.map(color => {
        return { ...color, color: color.color ?? 'brown' };
      }),
      EntireMouth: mouthDotsChecked.entireMouth.map(product => {
        return {
          color: product.color ?? 'brown',
          code: product.code,
          name: product.name,
          hexColor: colors[product.color ?? 'brown'],
        };
      }),
    },
  });

  return await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: body,
  });
};
